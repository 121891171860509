import { useForm } from 'react-hook-form';
import { backendFormResolver } from '../../utils/validate';
import ApiError from '@/services/Api/ApiError';
import ApiService from '@/services/Api/ApiService';
export async function validate(input) {
    try {
        const url = input.id ? `v3/organization/${input.id}` : `v3/organization`;
        await ApiService.request({
            method: 'POST',
            url,
            data: input,
            params: { validateOnly: true },
        });
        // If 200 there are no errors
        return null;
    }
    catch (e) {
        if (e instanceof ApiError) {
            return e.response?.data?.errors ?? null;
        }
        else {
            throw e;
        }
    }
}
export function useFormOrganization(options) {
    return useForm({
        ...options,
        resolver: backendFormResolver(validate),
    });
}
export function useSubForm(fields, options = {}) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return useForm({
        ...options,
        resolver: backendFormResolver(validate, fields),
    });
}
export const errorTranslationKeyMap = {
    name: 'components.organizationForm.name',
    additionalAddress: 'components.organizationForm.billingAddress.additionalAddress',
    billingAddress: 'components.organizationForm.errorMessage.billingAddress',
    street: 'components.organizationForm.billingAddress.street',
    number: 'components.organizationForm.billingAddress.number',
    zip: 'components.organizationForm.billingAddress.zip',
    city: 'components.organizationForm.billingAddress.city',
    country: 'components.organizationForm.billingAddress.country',
    state: 'components.organizationForm.billingAddress.state',
    taxId: 'components.organizationForm.tax.taxId',
    vatId: 'components.organizationForm.tax.vatId',
    email: 'components.organizationForm.contact.email',
    paymentInfo: 'components.organizationForm.paymentInfo.title',
    invoiceEmailInterval: 'components.organizationForm.emailPreferences.invoiceIntervalInfoHeadline',
    invoiceRecipientEmails: 'components.organizationForm.emailPreferences.emailInvoice',
    deliveryNoteEmailInterval: 'components.organizationForm.emailPreferences.deliveryNoteInfoHeadline',
    deliveryNoteRecipientEmails: 'components.organizationForm.emailPreferences.emailCreditNote',
};
