export var StatusEnum;
(function (StatusEnum) {
    StatusEnum["NEW"] = "new";
    StatusEnum["ASSIGNED"] = "assigned";
    StatusEnum["PLANNED"] = "planned";
    StatusEnum["IN_TRANSIT"] = "inTransit";
    StatusEnum["TRANSIT_COMPLETED"] = "transitCompleted";
    StatusEnum["FULFILLED"] = "fulfilled";
})(StatusEnum || (StatusEnum = {}));
export var ServiceDateTypeEnum;
(function (ServiceDateTypeEnum) {
    ServiceDateTypeEnum["PERIOD"] = "period";
    ServiceDateTypeEnum["TIME_POINT"] = "timePoint";
})(ServiceDateTypeEnum || (ServiceDateTypeEnum = {}));
