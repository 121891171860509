import { asVueComponent } from '@/reactBridge/asVueComponent';
import { platformInfoQuery } from '@/reactBridge/queryClient';
import { CloseIcon } from '@schuettflix/planum-icons-react';
import { LoadingSpinner } from '@schuettflix/react-components';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { End } from './components/End';
import { UrlCreator } from './components/UrlCreator';
import { OrganizationForm } from './forms/organization/OrganizationForm';
import { UserForm } from './forms/user/UserForm';
import { MagicContext, useMagicContext } from './utils/context';
import { extractDataFromUrl } from './utils/link';
import { ConstructionProjectForm } from './forms/constructionProject/ConstructionProjectForm';
export function MagicInner() {
    const { t } = useTranslation();
    const [scrollPosition, setScrollPosition] = useState('top');
    const magicData = extractDataFromUrl();
    const ctx = useContext(MagicContext);
    const [showUrlCreator, setShowUrlCreator] = useState(false);
    const platformInfo = useQuery(platformInfoQuery);
    const footerSlot = useRef(null);
    useEffect(() => {
        if (ctx.state.magicData === null && magicData) {
            ctx.dispatch({ magicData });
        }
    }, [magicData, ctx]);
    const getStep = useCallback((step) => {
        if (!magicData)
            throw new Error('magicData is undefined');
        if (!step) {
            return <LoadingSpinner />;
        }
        switch (step) {
            case 'end': {
                return <End totalSteps={ctx.state.availableSteps.length}/>;
            }
            case 'organizations': {
                const data = magicData.organizations[0];
                return (<OrganizationForm defaultValues={data.data} buttonSlot={footerSlot.current ?? undefined}/>);
            }
            case 'users': {
                return <UserForm buttonSlot={footerSlot.current ?? undefined}/>;
            }
            case 'constructionProjects': {
                const data = magicData.constructionProjects[0];
                return <ConstructionProjectForm buttonSlot={footerSlot.current ?? undefined} linkData={data}/>;
            }
            default: {
                return <div>Step {step} not implemented yet ™️</div>;
            }
        }
    }, [magicData, ctx.state.availableSteps]);
    // RENDER
    if (!magicData) {
        return (<div>
                <p>Link Invalid</p>
                <UrlCreator />
            </div>);
    }
    return (<div className="fixed inset-0 z-10 flex max-h-full flex-col bg-light-gray-200">
            <nav className={clsx('z-10 flex justify-between gap-4 bg-surface px-6 py-4 transition-shadow', scrollPosition !== 'top' && 'shadow-[0px_-10px_50px_-20px_#000000c7]')}>
                <div className="flex flex-col gap-2">
                    <h1 className="font-headline-lg-strong">{t('pages.organization.header.title')}</h1>
                    <div className="font-copy-sm text-subdued">
                        {ctx.state.stepNumber > 0 &&
            t('pages.organization.header.step.description', {
                step: ctx.state.stepNumber,
                totalSteps: ctx.state.availableSteps.length,
                currentState: t(`pages.magic.header.step.${ctx.state.step}`),
            })}
                    </div>
                </div>
                <div className="flex items-center">
                    <a href="/#/">
                        <button type="button" className="flex items-center gap-2">
                            <p className="font-copy-md-strong">{t('pages.magic.header.labelButton.close')}</p>
                            <CloseIcon size={24}/>
                        </button>
                    </a>
                </div>
            </nav>

            <div className="flex-1 overflow-y-auto" style={{ scrollbarGutter: 'stable both-edges' }} onScroll={e => {
            if (e.target instanceof HTMLDivElement) {
                const s = e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight);
                setScrollPosition(s === 0 ? 'top' : s === 1 ? 'bottom' : 'middle');
            }
        }}>
                {/* CONTENT */}
                {getStep(ctx.state.step)}
                {showUrlCreator && <UrlCreator />}
            </div>
            <footer onClick={e => {
            if (e.shiftKey) {
                e.preventDefault();
                setShowUrlCreator(!showUrlCreator);
            }
        }} className={clsx('z-10 bg-light-gray-200 transition-shadow', scrollPosition !== 'bottom' && 'shadow-[0px_10px_50px_-20px_#000000c7]')}>
                <div className="mx-auto flex max-w-screen-md items-center justify-end gap-4 py-4">
                    {platformInfo.isLoading ? (<LoadingSpinner />) : (<div>
                            {t('components.organizationForm.market.label')}:{' '}
                            {t(platformInfo.data?.platform.market.name ?? '')}
                        </div>)}
                    <div ref={footerSlot} className="contents"/>
                </div>
            </footer>
        </div>);
}
export const Magic = asVueComponent(() => {
    const ctx = useMagicContext();
    return (<MagicContext.Provider value={ctx}>
            <MagicInner />
        </MagicContext.Provider>);
});
