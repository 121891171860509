import { StatusEnum } from '@/pages/Order/TransportList/TransportTypes';
export function mapToTransportListItems(transportResponseData) {
    return (transportResponseData?.items ?? []).map(transportItem => ({
        id: transportItem.transportId,
        transportNumber: transportItem.transportNumber.replace(`${transportItem.orderNumber}-`, ''),
        quantity: transportItem.cargo.payload?.value ?? transportItem.cargo.requestedPayload?.value,
        unit: transportItem.cargo.payload?.unit ?? transportItem.cargo.requestedPayload?.unit,
        reference: transportItem.cargo.weighingNoteNumber ?? '---',
        carrierName: transportItem.carrier?.name ?? '---',
        status: transportItem.status,
        serviceDate: getTransportServiceDate(transportItem),
    }));
}
function getTransportServiceDate(transportResponseItem) {
    switch (transportResponseItem.status) {
        case StatusEnum.IN_TRANSIT:
        case StatusEnum.TRANSIT_COMPLETED:
        case StatusEnum.PLANNED: {
            const serviceDate = transportResponseItem.locations.find((location) => location.planning)
                .planning?.plannedTime;
            return {
                type: 'timePoint',
                at: serviceDate,
            };
        }
        case StatusEnum.NEW:
        case StatusEnum.ASSIGNED:
        case StatusEnum.FULFILLED: // TODO: change 'fulfilled' state to return the correct serviceDate when added to the useTransportsScopedByOrder Hook
            return transportResponseItem.locations.find((location) => location.requestedShippingSchedule)?.requestedShippingSchedule;
        default:
            return;
    }
}
