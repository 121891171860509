export function resolveProductIcon(product) {
    switch (product.productType) {
        case 'MATERIAL_SERVICE':
        case 'TRANSPORT_SERVICE':
            return 'service';
        case 'TRANSPORT':
            return 'transport';
        case 'MATERIAL':
        case 'MATERIAL_TRANSPORT':
        default:
            return 'material';
    }
}
