import { useTranslation } from 'react-i18next';
import { formatCurrency } from '@/services/utils';
const BILLING_TYPE_LABEL = {
    CREDIT_NOTE: 'billingMethod.CREDIT_NOTE',
    INVOICE: 'billingMethod.INVOICE',
    PARTNER_INVOICE: 'billingMethod.PARTNER_INVOICE',
};
export function ProductSummary(props) {
    const { t } = useTranslation();
    const financeSettingsForTransaction = props.financeSettings.find(it => it.transactionalRole === props.transaction.transactionalRole);
    const billingMethodName = financeSettingsForTransaction?.billingMethod
        ? t(BILLING_TYPE_LABEL[financeSettingsForTransaction?.billingMethod])
        : null;
    return (<div className="font-copy-md divide-y divide-divider">
            <div className="flex flex-row justify-between gap-4 py-2">
                <div>{props.transaction.productName}</div>
                <div className="flex flex-row gap-4">
                    <span>{props.transaction.unitTaxClass?.rate}%</span>
                    <span>
                        {formatCurrency(props.transaction.unitPriceAmount, props.transaction.unitPriceCurrency)}/
                        {t(`units.${props.transaction.unit.toLowerCase()}`)}
                    </span>
                </div>
            </div>
            <div className="flex flex-row justify-between gap-4 py-2">
                <div className="text-subdued">{t('pages.genericPosition.paymentTerms')}</div>
                <div>{financeSettingsForTransaction?.paymentTerm?.localizedDescription ?? '-'}</div>
            </div>
            <div className="flex flex-row justify-between gap-4 py-2">
                <div className="text-subdued">{t('pages.genericPosition.billingMethod')}</div>
                <div>{billingMethodName ? billingMethodName : '-'}</div>
            </div>
        </div>);
}
