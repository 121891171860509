import { Card } from '@/pages/Magic/components/Card';
import { InfoPreview } from '@/pages/Magic/components/InfoPreview';
import { useAvailableLocales } from '@/pages/Magic/hooks/locales';
import { formatPhone } from '@/pages/Magic/utils/formatters';
import { useTranslation } from 'react-i18next';
import { ErrorOverView } from '@/pages/Magic/components/ErrorOverview';
import { errorTranslationKeyMap } from '../form';
import { hasErrors } from '@/pages/Magic/utils/form';
import { BaseInformationFields } from './common';
import { useOrganizationById } from '@/pages/Magic/hooks/api';
export function BaseInformationPreview(props) {
    const { t } = useTranslation();
    const availableLocales = useAvailableLocales();
    const values = props.form.getValues();
    const errors = hasErrors(props.form.formState.errors, BaseInformationFields);
    const selectedLocale = availableLocales.find(l => l.locale === values.locale)?.displayName ?? null;
    const name = values.firstName || values.lastName ? `${values.firstName} ${values.lastName}` : null;
    const org = useOrganizationById(values.organization.id);
    return (<div className="flex flex-col gap-2 pb-2">
            <Card title={t('pages.user.userForm.titlePreview.user')} onEdit={props.onEdit}>
                <ErrorOverView errors={errors} errorTranslationKeyMap={errorTranslationKeyMap}/>
                <div className="grid grid-cols-2 gap-2">
                    <InfoPreview label={t('pages.user.userForm.placeholder.client')} className="col-span-2">
                        {org.data?.name}
                    </InfoPreview>
                    <InfoPreview label={t('pages.organization.view.label.name')}>{name}</InfoPreview>
                    <InfoPreview label={t('pages.user.userForm.placeholder.username')}>{values.username}</InfoPreview>
                    <InfoPreview label={t('pages.user.userForm.placeholder.email')}>{values.email}</InfoPreview>
                    <InfoPreview label={t('components.organizationForm.contact.mobile')}>
                        {formatPhone(values.mobile)}
                    </InfoPreview>
                    <InfoPreview label={t('pages.user.userForm.placeholder.locale')}>{selectedLocale}</InfoPreview>
                </div>
            </Card>
        </div>);
}
