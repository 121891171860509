import { getProjects, getFavoriteConstructionProjects, getConstructionProjectById, getLocations, getLocationDraftByConstructionProjectId, getOrganizationOptions, getNotificationSetting, getAdminOrganizationOptions, getAdminOrganizations, getConstructionProjectAdminById, getAdminConstructionProjectsMetadata, } from './api/projects';
import { httpClient } from './api/client/http-client';
export const constructionProjectKeys = {
    namespace: ['construction-projects'],
    list: () => [...constructionProjectKeys.namespace, 'list'],
    favorites: () => [...constructionProjectKeys.namespace, 'favorites'],
    byId: (id) => [...constructionProjectKeys.namespace, id],
    byIdForAdmin: (id) => [...constructionProjectKeys.namespace, 'admin', id],
    counts: (id) => [...constructionProjectKeys.byId(id), 'counts'],
    notificationSetting: (id) => [...constructionProjectKeys.byId(id), 'notification-setting'],
    locations: (constructionProjectId) => [...constructionProjectKeys.byId(constructionProjectId), 'locations'],
    locationDraft: (constructionProjectId) => [...constructionProjectKeys.byId(constructionProjectId), 'location-draft'],
    organizationOptions: () => [...constructionProjectKeys.namespace, 'organizations', 'options'],
    adminOrganizationOptions: (organizationId) => [...constructionProjectKeys.namespace, 'organizations', organizationId, 'options'],
    adminOrganizations: () => [...constructionProjectKeys.namespace, 'adminOrganizations'],
    adminConstructionProjects: ({ page, size, status, searchTerm, responsibleEmployeeId, organizationList, }) => [
        ...constructionProjectKeys.allAdminConstructionProjects(),
        page,
        size,
        status,
        searchTerm,
        responsibleEmployeeId,
        organizationList,
    ],
    allAdminConstructionProjects: () => [...constructionProjectKeys.namespace, 'adminConstructionProjects'],
    adminConstructionProjectsMetadata: () => [...constructionProjectKeys.namespace, 'adminConstructionProjectsMetadata'],
    monolithProjectId: (id) => ['project-id', id],
};
export const constructionProjectListQuery = {
    queryKey: constructionProjectKeys.list(),
    queryFn: getProjects,
};
export const constructionProjectFavoritesQuery = {
    queryKey: constructionProjectKeys.favorites(),
    queryFn: getFavoriteConstructionProjects,
};
export const constructionProjectByIdQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.byId(id),
        queryFn: () => getConstructionProjectById(id),
    };
};
export const constructionProjectByOptionalIdAsAdminQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.byId(id ?? '_NULL_'),
        queryFn: () => (id ? getConstructionProjectAdminById(id) : null),
    };
};
export const constructionProjectByIdAsAdminQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.byId(id),
        queryFn: () => getConstructionProjectAdminById(id),
    };
};
export const constructionProjectNotificationSettingQuery = (id) => {
    return {
        queryKey: constructionProjectKeys.notificationSetting(id),
        queryFn: () => getNotificationSetting(id),
    };
};
export const constructionProjectLocationsQuery = (constructionProjectId) => {
    return {
        queryKey: constructionProjectKeys.locations(constructionProjectId),
        queryFn: () => getLocations(constructionProjectId),
    };
};
export const constructionProjectLocationDraftQuery = (constructionProjectId) => {
    return {
        queryKey: constructionProjectKeys.locationDraft(constructionProjectId),
        queryFn: () => getLocationDraftByConstructionProjectId(constructionProjectId),
    };
};
export const constructionProjectOrganizationOptionsQuery = {
    queryKey: constructionProjectKeys.organizationOptions(),
    queryFn: getOrganizationOptions,
};
export const adminConstructionProjectOrganizationOptionsQuery = (organizationId) => {
    return {
        queryKey: constructionProjectKeys.adminOrganizationOptions(organizationId),
        queryFn: () => getAdminOrganizationOptions(organizationId),
    };
};
export const adminConstructionProjectOrganizationsQuery = {
    queryKey: constructionProjectKeys.adminOrganizations(),
    queryFn: getAdminOrganizations,
};
export const adminConstructionProjectsMetadataQuery = () => {
    return {
        queryKey: constructionProjectKeys.adminConstructionProjectsMetadata(),
        queryFn: () => getAdminConstructionProjectsMetadata(),
        // needed to fix https://linear.app/schuettflix/issue/ASKP-35/probleme-beim-offnen-von-projekten-in-der-app
        // this is caused by loading > 7000 organizations and tanstack query trying to diff them
        // on iOS devices the call stack is only 8k items big
        structuralSharing: false,
    };
};
export function adminConstructionProjectsBySalesforceIdQuery(salesForceProjectId) {
    const queryParams = {
        searchTerm: salesForceProjectId ?? '',
        page: 1,
        size: 2, // We take 2, so we can check if the result is unique
        organizationList: [],
        responsibleEmployeeId: null,
        status: null,
    };
    return {
        queryKey: constructionProjectKeys.adminConstructionProjects(queryParams),
        queryFn: async () => {
            const searchResponse = await httpClient.get('/admin/v1/construction-projects', { params: queryParams });
            // Only if we have exactly 1 match, we have found one and unique.
            if (searchResponse.data.constructionProjects.length !== 1) {
                return null;
            }
            const projectId = searchResponse.data.constructionProjects[0].id;
            return getConstructionProjectAdminById(projectId);
        },
    };
}
