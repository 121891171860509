import { useForm } from 'react-hook-form';
import { backendFormResolver } from '../../utils/validate';
import ApiError from '@/services/Api/ApiError';
import ApiService from '@/services/Api/ApiService';
export async function validate(input) {
    try {
        const url = input.id ? `v2/user/${input.id}` : `v2/user`;
        await ApiService.request({
            method: 'POST',
            url,
            data: input,
            params: { validateOnly: true },
        });
        // If 200 there are no errors
        return null;
    }
    catch (e) {
        if (e instanceof ApiError) {
            return e.response?.data?.errors ?? null;
        }
        else {
            throw e;
        }
    }
}
export function useUserForm(options) {
    return useForm({
        ...options,
        resolver: backendFormResolver(validate),
    });
}
export function useUserSubForm(fields, options = {}) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return useForm({
        ...options,
        resolver: backendFormResolver(validate, fields),
    });
}
export const errorTranslationKeyMap = {};
