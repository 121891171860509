import { hasErrors } from '@/pages/Magic/utils/form';
import { netsuitePaymentTermsQuery } from '@/reactBridge/queryClient';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../components/Card';
import { ErrorOverView } from '../../../components/ErrorOverview';
import { InfoPreview } from '../../../components/InfoPreview';
import { Section } from '../../../components/Section';
import { errorTranslationKeyMap } from '../form';
import { useIsOrgType } from '../hook';
import { AccountingFields } from './common';
import { useBillingIntervals } from './hooks';
export function AccountingPreview(props) {
    const { t } = useTranslation();
    const values = props.form.getValues();
    const errors = hasErrors(props.form.formState.errors, AccountingFields);
    const isType = useIsOrgType(values.types);
    const paymentMethodTranslations = {
        invoice: 'components.organizationForm.paymentInfo.paymentMethod.invoice.radioButtonTitle',
        direct_debit: 'components.organizationForm.paymentInfo.paymentMethod.directDebit.radioButtonTitle',
    };
    const paymentTermsQueryResult = useQuery(netsuitePaymentTermsQuery);
    function arrayDisplay(values) {
        if (!values || values.length === 0)
            return null;
        return (<ul>
                {values.map(value => (<li key={value}>{value}</li>))}
            </ul>);
    }
    function displayPaymentTerm(type, id) {
        return paymentTermsQueryResult.data?.paymentTerms[type].find(value => value.id === id)?.description ?? null;
    }
    const billingIntervals = useBillingIntervals();
    function displayPaymentInterval(type, group, interval) {
        if (billingIntervals.isLoading)
            return undefined;
        const id = billingIntervals.intervals[type].find(i => i.interval.group === group && i.interval.interval === interval)?.id;
        return id ? t(`pages.organization.form.interval.${id}`) : undefined;
    }
    return (<Card title={t('pages.organization.form.accountingInfo.headline')} onEdit={props.onEdit}>
            <div>
                <ErrorOverView errors={errors} errorTranslationKeyMap={errorTranslationKeyMap}/>

                {isType.client && (<Section title={t('pages.organization.accounting.headline')}>
                        <p className="font-copy-sm col-span-2 text-subdued">
                            {t('pages.organization.accounting.client.title')}
                        </p>
                        <InfoPreview label={t('pages.organization.form.accounting.dunning.label')} className="col-span-2">
                            {t(values.excludedFromDunning
                ? 'components.organizationForm.netsuite.excludedFromDunningActive'
                : 'components.organizationForm.netsuite.excludedFromDunningInactive')}
                        </InfoPreview>
                        <InfoPreview label={t('pages.organization.form.accounting.payment.label')}>
                            {displayPaymentTerm('client', values.clientPaymentTerm?.id)}
                        </InfoPreview>

                        <InfoPreview label={t('pages.organization.form.accounting.interval.label')}>
                            {displayPaymentInterval('invoice', values.invoiceBillingGroup, values.invoiceBillingInterval)}
                        </InfoPreview>
                    </Section>)}

                {(isType.carrier || isType.supplier) && (<Section>
                        <p className="font-copy-sm col-span-2 pt-2 text-subdued">
                            {t('pages.organization.accounting.supplier.title')}
                        </p>
                        <InfoPreview label={t('pages.organization.form.accounting.inkInvoice.label')} className="col-span-2">
                            {t(values.isPartnerInvoicingEnabled
                ? 'pages.organization.form.accounting.inkInvoice.enabled'
                : 'pages.organization.form.accounting.inkInvoice.disabled')}
                        </InfoPreview>
                        {isType.supplier && (<>
                                <InfoPreview label={t('pages.organization.form.accounting.paymentSupplier.label')}>
                                    {displayPaymentTerm('supplier', values.supplierPaymentTerm?.id)}
                                </InfoPreview>
                                <InfoPreview label={t('pages.organization.form.accounting.intervalSupplier.label')}>
                                    {displayPaymentInterval('creditNote', values.creditNoteSupplierBillingGroup, values.creditNoteSupplierBillingInterval)}
                                </InfoPreview>
                            </>)}
                        {isType.carrier && (<>
                                <InfoPreview label={t('pages.organization.form.accounting.paymentDriver.label')}>
                                    {displayPaymentTerm('carrier', values.carrierPaymentTerm?.id)}
                                </InfoPreview>

                                <InfoPreview label={t('pages.organization.form.accounting.intervalDriver.label')}>
                                    {displayPaymentInterval('creditNote', values.creditNoteCarrierBillingGroup, values.creditNoteCarrierBillingInterval)}
                                </InfoPreview>
                            </>)}
                    </Section>)}

                <hr className="my-6 border-divider"/>

                <Section title={t('components.organizationForm.paymentInfo.title')}>
                    <InfoPreview label={t('components.organizationForm.paymentInfo.paymentMethod.title')} className="col-span-2">
                        {t(paymentMethodTranslations[values.paymentInfo?.method])}
                    </InfoPreview>
                    <InfoPreview label={t('pages.organization.view.label.iban')}>
                        {values.paymentInfo?.iban}
                    </InfoPreview>
                    <InfoPreview label={t('components.organizationForm.paymentInfo.name')}>
                        {values.paymentInfo?.name}
                    </InfoPreview>

                    <InfoPreview label={t('components.organizationForm.paymentInfo.bic')}>
                        {values.paymentInfo?.bic}
                    </InfoPreview>
                    <InfoPreview label={t('components.organizationForm.paymentInfo.owner')}>
                        {values.paymentInfo?.owner}
                    </InfoPreview>
                </Section>

                <hr className="my-6 border-divider"/>

                <Section title={t('pages.organization.view.headlines.emailPreferences')}>
                    <p className="font-copy-sm col-span-2 text-subdued">
                        {t('components.organizationForm.emailPreferences.invoiceIntervalInfoHeadline')}
                    </p>
                    <InfoPreview label={t('components.organizationForm.emailPreferences.invoiceIntervalSelectLabel')}>
                        {values.invoiceEmailInterval
            ? t(`pages.organization.view.value.emailInterval.${values.invoiceEmailInterval}`)
            : null}
                    </InfoPreview>

                    <InfoPreview label={t('pages.organizationForm.emailPreferences.email.label')}>
                        {arrayDisplay(values.invoiceRecipientEmails)}
                    </InfoPreview>

                    <p className="font-copy-sm col-span-2 pt-2 text-subdued">
                        {t('components.organizationForm.emailPreferences.deliveryNoteInfoHeadline')}
                    </p>
                    <InfoPreview label={t('components.organizationForm.emailPreferences.invoiceIntervalSelectLabel')}>
                        {values.deliveryNoteEmailInterval
            ? t(`pages.organization.view.value.emailInterval.${values.deliveryNoteEmailInterval}`)
            : null}
                    </InfoPreview>

                    <InfoPreview label={t('pages.organizationForm.emailPreferences.email.label')}>
                        {arrayDisplay(values.deliveryNoteRecipientEmails)}
                    </InfoPreview>
                </Section>
            </div>
        </Card>);
}
