import React, { Suspense } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnalyticsProvider } from '@schuettflix/analytics-react';
import { analyticsService } from '@/services/Analytics/new';
import { EmitVueEventProvider } from './EmitVueEventProvider';
import { VueRouterProvider } from './VueRouterProvider';
import { queryClient } from './queryClient';
import { VueToastProvider } from './ToastProvider';
import Toaster from '@/services/Toaster';
import { QueryProvider } from '@schuettflix/app-transport';
export const ReactContextProviders = props => {
    return (<QueryProvider>
            <QueryClientProvider client={queryClient}>
                <AnalyticsProvider service={analyticsService}>
                    <VueRouterProvider vueRouter={props.vueRouter}>
                        <EmitVueEventProvider emitVueEvent={props.emitVueEvent}>
                            <VueToastProvider toaster={Toaster}>
                                <Suspense>{props.children}</Suspense>
                            </VueToastProvider>
                        </EmitVueEventProvider>
                    </VueRouterProvider>
                </AnalyticsProvider>
                <ReactQueryDevtools />
            </QueryClientProvider>
        </QueryProvider>);
};
