import { MessageBox } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
export function ErrorOverView(props) {
    const { t } = useTranslation();
    if (!props.errors.length)
        return null;
    return (<MessageBox type="warning" className="mb-6 mt-4">
            {t('pages.organization.form.baseInfo.warning.title')}
            <br />
            <ul className="mt-1 flex list-disc flex-col pl-4">
                {props.errors.map(error => (<li key={error}>
                        {error in props.errorTranslationKeyMap ? t(props.errorTranslationKeyMap[error]) : error}
                    </li>))}
            </ul>
        </MessageBox>);
}
