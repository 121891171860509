import { useContext } from 'react';
import { Card } from './Card';
import { Confetti } from './Confetti';
import { MagicContext } from '../utils/context';
export function End(props) {
    const ctx = useContext(MagicContext);
    return (<>
            <div className="m-auto max-w-screen-md py-8">
                <Card title="Success">
                    <h2 className="font-headline-md-strong">You have created {props.totalSteps} entities</h2>
                    <ul>
                        <li>{ctx.state.savedOrganizations.length} Organizations</li>
                        <li>{ctx.state.savedUsers.length} Users</li>
                        {/* <li>{props.created.constructionProjects} Construction Projects</li> */}
                    </ul>
                </Card>
            </div>
            <Confetti />
        </>);
}
