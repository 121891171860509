export const AccountingFields = [
    'id',
    'types',
    // Accounting
    'excludedFromDunning',
    'invoiceBillingGroup',
    'invoiceBillingInterval',
    'isPartnerInvoicingEnabled',
    'creditNoteCarrierBillingGroup',
    'creditNoteCarrierBillingInterval',
    'creditNoteSupplierBillingGroup',
    'creditNoteSupplierBillingInterval',
    'clientPaymentTerm.id',
    'carrierPaymentTerm.id',
    'supplierPaymentTerm.id',
    // IBAN
    'paymentInfo.bic',
    'paymentInfo.iban',
    'paymentInfo.method',
    'paymentInfo.name',
    'paymentInfo.owner',
    // EMail
    'invoiceEmailInterval',
    'invoiceRecipientEmails',
    'deliveryNoteEmailInterval',
    'deliveryNoteRecipientEmails',
    'emailDeliveryNoteAsZip',
    'emailReceiptAsZip',
    'emailDeliveryNotesAttachedToReceipt',
    'mandateReference.acceptedAt',
    'mandateReference.id',
];
