import { Loader } from '@/pages/Magic/components/Loader';
import { objToArr } from '@/pages/Magic/utils/data';
import { organizationPermissionsQuery } from '@/reactBridge/queryClient';
import { Checkbox } from '@schuettflix/planum-react';
import { Accordion, Button } from '@schuettflix/react-components';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useUserSubForm } from '../form';
import { SettingsFields } from './common';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
export function SettingsEdit(props) {
    const { t } = useTranslation();
    const form = useUserSubForm(SettingsFields, {
        defaultValues: props.defaultValue,
        mode: 'all',
    });
    useEffect(() => {
        form.trigger();
    }, [form]);
    const permissions = form.watch('permissions');
    const orgId = form.watch('organization.id');
    const orgPermissions = useQuery(organizationPermissionsQuery(orgId));
    const derived = useMemo(() => {
        const all = orgPermissions.data ? Object.values(orgPermissions.data).flatMap(s => objToArr(s)) : null;
        const required = all
            ?.filter(p => permissions.includes(p.key))
            .map(p => p.requiredPermissions)
            .flat() ?? null;
        return { all, required };
    }, [orgPermissions.data, permissions]);
    function toggle(permission) {
        if (permissions.includes(permission)) {
            form.setValue('permissions', permissions.filter(p => p !== permission));
        }
        else {
            const obj = derived.all?.find(p => p.key === permission);
            if (!obj)
                return;
            form.setValue('permissions', [...permissions, permission, ...obj.requiredPermissions]);
        }
    }
    function formatRequired(required) {
        return (derived.all
            ?.filter(p => required.includes(p.key))
            .map(p => `"${p.title}"`)
            .join(', ') ?? null);
    }
    if (orgPermissions.isLoading)
        return <Loader />;
    return (<form onSubmit={form.handleSubmit(props.onSave)}>
            <div className="mx-auto flex w-full max-w-screen-md flex-col">
                <p className="font-copy-lg pb-8 pt-16">{t('pages.magic.userForm.permissions.title')}</p>
                {!!orgPermissions.data && (<Accordion size="md">
                        {Object.entries(orgPermissions.data).map(([key, section]) => {
                const permissionsArr = objToArr(section);
                return (<Accordion.Item key={key} title={t(`pages.magic.user.userForm.permissions.${key}`)}>
                                    <ul className="flex flex-col gap-4">
                                        {permissionsArr.length === 0 ? (<p className="text-subdued">
                                                {t('pages.magic.user.userForm.permissions.noPermissions')}
                                            </p>) : (permissionsArr.map(permission => {
                        const isRequired = derived.required?.includes(permission.key);
                        return (<li key={permission.key} className="flex gap-4">
                                                        <Checkbox isDisabled={isRequired} stature="md" isSelected={permissions.includes(permission.key)} onChange={() => toggle(permission.key)}/>
                                                        <div className={clsx('flex flex-col gap-1', isRequired && 'opacity-60')}>
                                                            <div className="font-copy-md-strong">
                                                                {permission.title}
                                                            </div>
                                                            <p className="font-copy-sm">{permission.description}</p>
                                                            {permission.requiredPermissions.length > 0 && (<div className="font-copy-xs">
                                                                    <span className="text-critical">
                                                                        {t('components.hint.label')}
                                                                    </span>
                                                                    {t('pages.user.userForm.permissionHintText', {
                                    permissions: formatRequired(permission.requiredPermissions),
                                })}
                                                                </div>)}
                                                        </div>
                                                    </li>);
                    }))}
                                    </ul>
                                </Accordion.Item>);
            })}
                    </Accordion>)}

                <div className="flex justify-center gap-4 py-16">
                    <Button type="reset" onClick={props.onCancel} label={t('pages.organization.form.button.cancel.label')} size="sm" variant="secondary"/>
                    <Button type="submit" label={t('pages.organization.form.button.submit.label')} size="sm"/>
                </div>
            </div>
        </form>);
}
