import { createAdminConstructionProject, updateConstructionProjectAdminDetail, } from '@/constructionProjects/api/projects';
import { FormField } from '@/constructionProjects/components/FormField';
import { useBreakpoints } from '@/constructionProjects/hooks/useBreakpoints';
import { useIsApp } from '@/constructionProjects/hooks/useIsApp';
import { ConstructionProjectForm as CPSForm, useConstructionProjectForm, } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectForm';
import { adminConstructionProjectOrganizationOptionsQuery, constructionProjectKeys, } from '@/constructionProjects/queries';
import { formatAddress } from '@/constructionProjects/utils/formatAddress';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import Toaster from '@/services/Toaster';
import { Combobox } from '@schuettflix/react-components';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { addDays, format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNextStep, MagicContext } from '../../utils/context';
import { useCheckExistingOrg } from '../organization/hook';
import { useCheckExistingUser } from '../user/hooks';
import { useCheckExistingConstructionProject } from './hooks';
export function ConstructionProjectForm(props) {
    const { t, i18n } = useTranslation();
    const { vueRouter } = useVueRouter();
    const queryClient = useQueryClient();
    const ctx = useContext(MagicContext);
    const [prefilled, setPrefilled] = useState(false);
    const suggestedOrganization = ctx.state.savedOrganizations.length === 1
        ? ctx.state.savedOrganizations[0].id
        : typeof vueRouter.currentRoute.query.organization === 'string'
            ? parseInt(vueRouter.currentRoute.query.organization)
            : NaN;
    const localizeComparator = new Intl.Collator(i18n.language);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(Number.isNaN(suggestedOrganization) ? null : suggestedOrganization);
    const [selectedPlatformAdmin, setSelectedPlatformAdmin] = useState(null);
    const { data: adminOrganizationOptions } = useQuery({
        ...adminConstructionProjectOrganizationOptionsQuery(selectedOrganizationId ?? -1),
        enabled: !!selectedOrganizationId,
    });
    const date = new Date();
    const isApp = useIsApp();
    const { isSmallerOrEqual } = useBreakpoints();
    const { form } = useConstructionProjectForm({
        defaultValues: {
            color: null,
            validFrom: format(date, 'YYYY-MM-DD'),
            validTo: isApp || isSmallerOrEqual('md') ? format(addDays(date, 30), 'YYYY-MM-DD') : undefined,
            active: true,
            responsibleUserId: selectedPlatformAdmin ?? undefined,
            ...props.linkData?.data,
            teamMembers: [],
            orderConfirmationMails: [],
        },
        costCenterMandatory: adminOrganizationOptions?.organizationMetadata?.costCenterMandatory ?? false,
    });
    const check = useCheckExistingConstructionProject(props.linkData?.data.id, props.linkData?.data.salesforceProjectId);
    useEffect(() => {
        if (check.project) {
            setPrefilled(true);
            /**
             * Remapping some data, because why would you use the same fields and same structure for GET, POST and PUT. Where is the fun in that?
             * https://media.tenor.com/9HtMukwMDiUAAAAC/omg-hell.gif
             */
            form.reset({
                ...check.project,
                validFrom: format(new Date(check.project.validFrom), 'YYYY-MM-DD'),
                validTo: format(new Date(check.project.validTo), 'YYYY-MM-DD'),
                teamMembers: check.project.teamMembers.map(tm => tm.id),
                location: {
                    coordinates: check.project.geoCoordinate,
                    address: {
                        formatted: null,
                        placeId: null,
                        country: check.project.country,
                        state: check.project.state,
                        city: check.project.city,
                        street: check.project.street,
                        streetNumber: check.project.addressNumber,
                        zipCode: check.project.zip,
                    },
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- it would cause an infinite loop
    }, [check.project]);
    {
        // Select responsible employee from link payload
        const check = useCheckExistingUser(props.linkData?.extra?.refs?.responsibleEmployee?.platformId, props.linkData?.extra?.refs?.responsibleEmployee?.salesforceContactId);
        useEffect(() => {
            if (prefilled)
                return;
            if (selectedPlatformAdmin === null && check.user?.id) {
                setSelectedPlatformAdmin(check.user.id);
            }
        }, [check.user, selectedPlatformAdmin, prefilled]);
    }
    {
        // Select responsible user from link payload
        const check = useCheckExistingUser(props.linkData?.extra?.refs?.responsibleUser?.platformId, props.linkData?.extra?.refs?.responsibleUser?.salesforceContactId);
        const responsibleUser = form.watch('responsibleUserId');
        useEffect(() => {
            if (prefilled)
                return;
            if (!responsibleUser && check.user?.id) {
                form.setValue('responsibleUserId', check.user.id);
            }
        }, [check.user, responsibleUser, form, prefilled]);
    }
    const mutationEvents = {
        onSuccess: () => {
            AnalyticsService.trackEvent('construction_project', { step: 'construction_project_created' });
            queryClient.invalidateQueries(constructionProjectKeys.list());
            Toaster.success(t('pages.constructionProject.createForm.toaster.success'));
        },
        onError: (error) => {
            if (!(error instanceof AxiosError))
                return;
            if (!error.response || error.response.status > 400) {
                Toaster.error(t('pages.constructionProject.createForm.toaster.unspecifiedServerError'));
            }
            if (error.response?.status === 400) {
                const fieldErrors = error.response?.data?.fieldErrors;
                for (const [key, error] of Object.entries(fieldErrors)) {
                    form.setError(
                    // @ts-expect-error These are backend keys and are untypable
                    key, {
                        type: 'server',
                        message: error.message,
                    }, { shouldFocus: true });
                }
            }
        },
    };
    const projectUpdate = useMutation((data) => {
        if (!check.project?.id)
            throw new Error('could not update the construction project');
        return updateConstructionProjectAdminDetail(check.project.id, data);
    }, mutationEvents);
    const projectCreate = useMutation(createAdminConstructionProject, mutationEvents);
    function onSubmitConstructionProjectCreateForm(formData) {
        if (!selectedOrganizationId || !selectedPlatformAdmin) {
            return;
        }
        const data = {
            name: formData.name,
            description: formData.description,
            validFrom: formData?.validFrom ? new Date(formData.validFrom).toISOString() : '',
            validTo: formData?.validTo ? new Date(formData.validTo).toISOString() : '',
            costCenter: formData.costCenter,
            color: formData.color,
            active: formData.active,
            bounds: [],
            responsibleUserId: formData.responsibleUserId,
            teamMembers: Array.from(new Set(formData.teamMembers)),
            city: formData.location.address.city,
            zip: formData.location.address.zipCode,
            street: formData.location.address.street,
            addressNumber: formData.location.address.streetNumber,
            geoCoordinate: formData.location.coordinates,
            organizationId: selectedOrganizationId,
            responsibleEmployeeId: selectedPlatformAdmin,
            orderConfirmationMails: formData.orderConfirmationMails,
            salesforceProjectId: formData.salesforceProjectId ?? null,
        };
        if (check.isNew) {
            projectCreate.mutate(data, {
                onSuccess: data => {
                    const state = structuredClone(ctx.state);
                    state.savedConstructionProjects.push(data);
                    state.step = getNextStep(ctx.state);
                    ctx.dispatch(state);
                },
            });
        }
        else {
            if (data.city === null || data.zip === null || data.street === null || data.addressNumber === null) {
                throw new Error();
            }
            const updateData = {
                ...data,
                color: data.color ?? undefined,
                city: data.city,
                zip: data.zip,
                street: data.street,
                addressNumber: data.addressNumber,
            };
            projectUpdate.mutate(updateData, {
                onSuccess: data => {
                    const state = structuredClone(ctx.state);
                    state.savedConstructionProjects.push(data);
                    state.step = 'end';
                    ctx.dispatch(state);
                },
            });
        }
    }
    const orgRef = props.linkData?.extra?.refs?.organization;
    const orgRefCheck = useCheckExistingOrg(orgRef?.platformId, orgRef?.salesforceAccountId);
    const orgOptions = (orgRefCheck.org ? [orgRefCheck.org] : ctx.state.savedOrganizations)?.map(organization => ({
        value: organization.id,
        label: organization.name,
        description: formatAddress(organization.billingAddress),
    }));
    const responsibleEmployeeOptions = adminOrganizationOptions?.platformUsers?.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }));
    return (<>
            <div className="mx-auto w-full max-w-screen-md pb-4">
                <p className="font-copy-lg px-6 pb-2 pt-16">{t('pages.magic.constructionProject.form.title')}</p>
                <div className="container-content flex flex-col bg py-6">
                    <FormField title={t('pages.constructionProject.createForm.organizationSelection.title')} description={t('pages.constructionProject.createForm.organizationSelection.description')}>
                        <Combobox className="mb-12 w-full" label={t('pages.constructionProject.createForm.organizationSelection.title')} multiple={false} options={orgOptions ?? []} value={selectedOrganizationId} onChange={value => setSelectedOrganizationId(value)}/>
                    </FormField>
                    {selectedOrganizationId ? (<FormField title={t('pages.constructionProject.createForm.responsibleEmployeeId.title')} description={t('pages.constructionProject.createForm.responsibleEmployeeId.description')}>
                            <Combobox className="w-full" label={t('pages.constructionProject.createForm.personField.label')} multiple={false} options={responsibleEmployeeOptions?.sort((a, b) => localizeComparator.compare(a.label, b.label)) ?? []} value={selectedPlatformAdmin} onChange={value => setSelectedPlatformAdmin(value)}/>
                        </FormField>) : null}
                </div>
                {selectedOrganizationId && selectedPlatformAdmin && adminOrganizationOptions ? (<CPSForm form={form} organizationUsers={adminOrganizationOptions.organizationUsers} organizationEmail={adminOrganizationOptions.organizationMetadata.email} selectedOrganizationId={selectedOrganizationId} submissionLoading={projectCreate.isLoading || projectUpdate.isLoading} onSubmit={onSubmitConstructionProjectCreateForm} submitButtonLabel={t(check.isNew
                ? 'pages.constructionProject.createForm.submitButton.label'
                : 'pages.constructionProject.editForm.saveButton.label')} cancelButtonLabel={t('pages.constructionProject.createForm.cancelButton.label')} buttonsPortal={props.buttonSlot} costCenterMandatory={adminOrganizationOptions.organizationMetadata.costCenterMandatory}/>) : null}
            </div>
        </>);
}
