<template>
    <LayoutPage class="organization-list" :child-routes="childRoutes" screen-name="platform-organization-list">
        <template #pageTitle>{{ $t('pages.organization.list.headline') }}</template>

        <RoundedTabNavigation
            :value="activePredefinedFilter"
            :tabs="availableTabs"
            :full-width-tabs="!$root.isDesktop"
            @input="updatePredefinedFilter"
        />

        <FilterBox
            v-model="filter"
            :default-filter="filter"
            :endpoint="dataEndpoint"
            :forced-filter="forcedFilter"
            inline-mode
            class="organization-list__advanced-filter"
            @update="refreshList(false, true, true)"
        >
            <template #default="{ filter: filterFromSlot, updateFilter }">
                <AdvancedFilterSet
                    :endpoint="dataEndpoint"
                    :forced-filter="forcedFilter"
                    :filter-scope="{ filter: filterFromSlot }"
                    :whitelisted-fields="whitelistedFields"
                    @updateFilter="updateFilter"
                />

                <FilterSortPagination
                    :result="organizations"
                    :filter="filterFromSlot"
                    :sort-options="sortOptions"
                    :option-label-renderer="value => $t(`pages.organization.list.sortings.${value}`)"
                    :hide-sort="!$root.isDesktop"
                    show-refresh
                    :loading="isLoading"
                    @refresh="refreshList(false, true, true)"
                    @pageNumberUpdated="pageNumberUpdated"
                >
                    <div v-if="$root.isDesktop" />
                </FilterSortPagination>
            </template>
        </FilterBox>

        <div v-can:createOrganization class="organization-list__actions">
            <BaseButton
                v-if="!pendingApprovalListIsActive && !isOldOrgCreationHidden"
                primary
                data-test="create-organization-button"
                @click="createNewOrganization()"
            >
                <template #left>
                    <PlusIcon class="icon--inline" />
                </template>
                {{ $t('pages.organization.list.actions.createOrganization') }}
            </BaseButton>
        </div>

        <LoadingSpinner v-if="!organizations" block dark />

        <transition name="fade" mode="out-in">
            <div v-if="organizations && organizations.count > 0">
                <GridTable
                    :columns="pendingApprovalListIsActive ? '1fr 2fr 1fr 0.6fr 100px' : '1fr 3fr 2fr 1fr 0.6fr 100px'"
                >
                    <template #head>
                        <GridTableHead v-stuckable="20">
                            <div>{{ $t('pages.organization.list.tableHeadings.number') }}</div>
                            <div>{{ $t('pages.organization.list.tableHeadings.name') }}</div>
                            <div>{{ $t('pages.organization.list.tableHeadings.type') }}</div>

                            <div v-if="!pendingApprovalListIsActive">
                                {{ $t('pages.organization.list.tableHeadings.isBlocked') }}
                            </div>
                            <div v-if="!pendingApprovalListIsActive">
                                {{ $t('pages.organization.list.tableHeadings.status') }}
                            </div>
                            <div v-if="pendingApprovalListIsActive">
                                {{ $t('pages.organization.list.tableHeadings.registrationDate') }}
                            </div>

                            <div>&nbsp;</div>
                        </GridTableHead>
                    </template>

                    <div v-for="(organizationFacet, i) in organizationFacets" :key="i">
                        <GridTableLabel
                            v-if="organizationFacet.count > 0 && pendingApprovalListIsActive"
                            v-stuckable="10"
                        >
                            {{ $t(`pages.organization.list.facets.${organizationFacet.appliedFacetValue}`) }}
                        </GridTableLabel>
                        <GridTableRow
                            v-for="organization in organizationFacet.items"
                            :key="`${organizationFacet.appliedFacetValue}-${organization.id}`"
                            tabled
                            @click="viewOrganization(organization.id, $event)"
                        >
                            <div class="grid-table__cell grid-table__cell--center">
                                {{ organization.customerNumber }}
                            </div>

                            <div v-if="pendingApprovalListIsActive" class="grid-table__cell grid-table__cell--center">
                                {{ organization.name }}, {{ organization.billingAddress.zip }},
                                {{ organization.billingAddress.city }},
                                {{ organization.billingAddress.state }}
                            </div>
                            <div v-else class="grid-table__cell grid-table__cell--center">
                                {{ organization.name }}
                            </div>

                            <div class="grid-table__cell grid-table__cell--center">
                                <div v-if="organization.types" class="organization-list__types">
                                    <Tag v-for="type in organization.types" :key="type" black small>
                                        {{ $t(`pages.user.userForm.organizationTypes.${type}`) }}
                                    </Tag>
                                </div>
                            </div>

                            <div v-if="!pendingApprovalListIsActive" class="grid-table__cell grid-table__cell--center">
                                <Words v-if="organization.isBlocked" green>
                                    {{ $t(`pages.organization.list.isBlockedActive`) }}
                                </Words>
                            </div>

                            <div v-if="!pendingApprovalListIsActive" class="grid-table__cell grid-table__cell--center">
                                <Words v-if="organization.isActive" green>
                                    {{ $t(`pages.organization.list.status.active`) }}
                                </Words>
                                <Words v-else muted>
                                    {{ $t(`pages.organization.list.status.inactive`) }}
                                </Words>
                            </div>

                            <div v-if="pendingApprovalListIsActive" class="grid-table__cell grid-table__cell--center">
                                {{ $d(organization.created * 1000, 'short') }}
                            </div>

                            <GridTableActionCell
                                v-if="pendingApprovalListIsActive && showApprovalButtons(organization)"
                            >
                                <BaseButton primary light @click.stop="openRejectConfirmation(organization.id)">
                                    {{ $t('pages.organization.list.pendingApproval.actions.reject') }}
                                </BaseButton>

                                <BaseButton primary @click.stop="approve(organization.id)">
                                    {{ $t('pages.organization.list.pendingApproval.actions.approve') }}
                                </BaseButton>
                            </GridTableActionCell>

                            <div class="flex justify-end p-[15px]" @click.stop.prevent>
                                <SfContextMenu
                                    :actions="menuActions(organization)"
                                    align="left"
                                    v-on="menuListeners(organization)"
                                />
                            </div>
                        </GridTableRow>
                    </div>
                </GridTable>

                <Card spaceless class="organization-list__bottom-pagination">
                    <Pagination align-right :result="organizations" @pageNumberUpdated="pageNumberUpdated" />
                </Card>
            </div>

            <Hint v-if="organizations && organizations.count === 0" center transparent>
                {{ $t('pages.organization.list.noResults') }}
            </Hint>
        </transition>

        <template #subpages>
            <Flyout route="organization-new" size="medium" />
        </template>

        <ModalBox ref="rejectApprovalConfirmation" :optional-dialog="false">
            {{ $t('pages.organization.list.pendingApproval.rejectionConfirmationText') }}

            <template #actions>
                <ButtonGroup>
                    <BaseButton primary light @click="closeRejectConfiramtion()">
                        {{ $t('pages.organization.list.pendingApproval.actions.cancel') }}
                    </BaseButton>
                    <BaseButton primary @click="confirmRejection()">
                        {{ $t('pages.organization.list.pendingApproval.actions.reject') }}
                    </BaseButton>
                </ButtonGroup>
            </template>
        </ModalBox>
    </LayoutPage>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _intersection from 'lodash/intersection';
import OrganizationApi from '@/services/Api/Organization';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import persistentFiltersMixin from '@/plugins/mixins/persistentFiltersMixin';
import Toaster from '@/services/Toaster';
import { SfContextMenu, SfDocumentsIcon, SfExternalLinkIcon, SfFacilitiesIcon } from '@schuettflix/vue-components';

import AdvancedFilterSet from './components/AdvancedFilterSet';
import BaseButton from '@/components/Button/Button';
import ButtonGroup from '@/components/Button/ButtonGroup';
import Card from '@/components/Layout/Card';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import Flyout from '@/components/Layout/Flyout';
import GridTable from '@/components/Table/GridTable';
import GridTableActionCell from '@/components/Table/GridTableActionCell';
import GridTableHead from '@/components/Table/GridTableHead';
import GridTableLabel from '@/components/Table/GridTableLabel';
import GridTableRow from '@/components/Table/GridTableRow';
import Hint from '@/components/Typography/Hint';
import LayoutPage from '@/components/Layout/Page.v2';
import ModalBox from '@/components/Modal/ModalBox';
import Pagination from '@/components/Pagination';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';
import Tag from '@/components/Typography/Tag';
import Words from '@/components/Typography/Words';

import { useLd } from '@/services/LaunchDarkly';

import LoadingSpinner from '@/components/LoadingSpinner';
import PlusIcon from '@/assets/icons/micro/plus.svg';
import { navigationFailure } from '@/services/utils/router';

const DEFAULT_PAGE_COUNT = 25;
let skipReAssembleFilter = false;

export default {
    name: 'OrganizationList',
    components: {
        AdvancedFilterSet,
        BaseButton,
        ButtonGroup,
        Card,
        FilterBox,
        FilterSortPagination,
        Flyout,
        GridTable,
        GridTableActionCell,
        GridTableHead,
        GridTableLabel,
        GridTableRow,
        Hint,
        LayoutPage,
        ModalBox,
        Pagination,
        RoundedTabNavigation,
        Tag,
        Words,
        SfContextMenu,

        LoadingSpinner,
        PlusIcon,
    },
    mixins: [persistentFiltersMixin, eventHubMixin],
    setup() {
        const isOldOrgCreationHidden = useLd('hide-old-organization-creation');
        return { isOldOrgCreationHidden };
    },
    data() {
        return {
            organizations: null,
            isLoading: false,
            cancelSource: null,
            dataEndpoint: OrganizationApi,
            // eslint-disable-next-line vue/no-undef-properties
            filter: this.assembleFilter('organization', {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
                status: ['approved'],
            }),
            defaultFilter: {
                page: 1,
                status: ['approved'],
            },
            forcedFilter: this.assembleFilter('organizationForced', {
                page: 1,
                perPage: DEFAULT_PAGE_COUNT,
            }),
            activePredefinedFilter: 'all',
            predefinedFilters: {
                all: {
                    status: ['approved'],
                },
                client: {
                    status: ['approved'],
                    organizationType: 'client',
                },
                carrier: {
                    status: ['approved'],
                    organizationType: 'carrier',
                },
                supplier: {
                    status: ['approved'],
                    organizationType: 'supplier',
                },
                pendingApproval: {
                    status: ['pending', 'postponed', 'rejected'],
                },
            },
            predefinedOptionalFilters: {
                all: {
                    page: 1,
                    perPage: 50,
                },
                client: {
                    page: 1,
                    perPage: 50,
                },
                carrier: {
                    page: 1,
                    perPage: 50,
                },
                supplier: {
                    page: 1,
                    perPage: 50,
                },
                pendingApproval: {
                    status: ['pending'],
                    page: 1,
                    perPage: 100,
                },
            },
            selectedOrganizationIdForRejection: null,
        };
    },
    computed: {
        sortOptions() {
            return _intersection(OrganizationApi.supportedSorts, ['name', 'id']);
        },
        organizationFacets() {
            return this.organizations.items.filter(item => item.count > 0);
        },
        pendingApprovalListIsActive() {
            return this.activePredefinedFilter === 'pendingApproval';
        },
        ignoreInFilterCount() {
            if (this.pendingApprovalListIsActive) {
                return ['organizationType'];
            } else {
                return ['organizationType', 'status'];
            }
        },
        availableTabs() {
            return {
                all: this.$t(`pages.user.userList.organizationTypes.all`),
                client: this.$t(`pages.user.userList.organizationTypes.client`),
                carrier: this.$t(`pages.user.userList.organizationTypes.carrier`),
                supplier: this.$t(`pages.user.userList.organizationTypes.supplier`),
                pendingApproval: this.$t(`pages.user.userList.organizationTypes.pendingApproval`),
            };
        },
        whitelistedFields() {
            if (this.activePredefinedFilter === 'pendingApproval') {
                return ['search', 'status'];
            }
            return ['search', 'isActive', 'isBlocked'];
        },
        childRoutes() {
            return [
                'management__organization-list__organization-view',
                'management__organization-list__admin-factory-management',
                'management__organization-list__admin-certificate-management',
            ];
        },
    },
    watch: {
        $route() {
            if (skipReAssembleFilter) {
                skipReAssembleFilter = false;
                return;
            }

            this.updateActivePredefinedFilter();
            this.filter = this.assembleFilter('organization', this.defaultFilter);
            this.forcedFilter = this.assembleFilter(
                'organizationForced',
                this.predefinedFilters[this.activePredefinedFilter]
            );
            this.refreshList(true);
        },
    },
    created() {
        this.refreshList(true);
        this.updateActivePredefinedFilter();

        // eslint-disable-next-line vue/no-undef-properties
        this.subscribe('organization.updated', () => {
            this.refreshList();
        });
    },
    methods: {
        async refreshList(isInitial = false, resetPagination = false, skipApplyFilter = false) {
            this.isLoading = true;

            // check if we have to reset the pagination
            if (resetPagination) {
                this.filter.page = 1;
            }

            // persist filter
            // eslint-disable-next-line vue/no-undef-properties
            this.persistFilter('organization', this.filter, this.defaultFilter);
            skipReAssembleFilter = skipApplyFilter;

            this.cancelSource && this.cancelSource.cancel('canceled-previous-call');
            this.cancelSource = OrganizationApi.createCancelTokenSource();

            try {
                const result = await OrganizationApi.filter(
                    this.filter,
                    'status',
                    [['approved'], ['pending'], ['postponed'], ['rejected']],
                    this.cancelSource,
                    this.forcedFilter
                );
                this.organizations = result;

                if (isInitial === true) {
                    this.filter = {
                        ...this.filter,
                        ...result.appliedFilter,
                    };
                }
            } catch (err) {
                if (err.code !== 400 && err.message !== 'canceled-previous-call') {
                    this.$logger().error(err);
                }
            }

            this.isLoading = false;
        },
        async viewOrganization(id, event) {
            if (event && event.shiftKey) {
                const org = await OrganizationApi.getOneById(id);
                this.$router
                    .push({
                        path: '/magic',
                        query: {
                            data: JSON.stringify({
                                organizations: [{ data: org }],
                                users: [],
                                constructionProjects: [],
                            }),
                        },
                    })
                    .catch(navigationFailure);
            } else {
                this.$router
                    .push({
                        name: this.$root.findRouteName('organization-view'),
                        params: { organizationId: id },
                        query: this.$route.query,
                    })
                    .catch(navigationFailure);
            }
        },

        showApprovalButtons(organization) {
            return organization.status !== 'approved' && organization.status !== 'rejected';
        },
        updateActivePredefinedFilter() {
            this.activePredefinedFilter = 'all';
            Object.keys(this.predefinedFilters).forEach(key => {
                // eslint-disable-next-line vue/no-undef-properties
                if (this.isQueryFilterActive('organizationForced', this.predefinedFilters[key])) {
                    this.activePredefinedFilter = key;
                }
            });
        },
        async workflowAction(action, organizationId) {
            if (!['approve', 'postpone', 'reject'].includes(action)) return;

            try {
                await OrganizationApi[action](organizationId);
                await this.refreshList();
            } catch (err) {
                this.$logger().error(err);
                if (err.code === 403) {
                    throw new Error(this.$t('pages.organization.list.pendingApproval.actions.alreadyDecieded'));
                }
                throw err;
            }
        },
        async approve(organizationId) {
            try {
                this.workflowAction('approve', organizationId);
                Toaster.success(this.$t('pages.organization.list.pendingApproval.notice.registrationConfirmed'));
            } catch (err) {
                Toaster.error(err);
            }
        },

        async reject(organizationId) {
            try {
                this.workflowAction('reject', organizationId);
                Toaster.success(this.$t('pages.organization.list.pendingApproval.notice.registrationRejected'));
            } catch (err) {
                Toaster.error(err);
            }
        },

        openRejectConfirmation(organizationId) {
            this.selectedOrganizationIdForRejection = organizationId;
            this.$refs.rejectApprovalConfirmation.$emit('open');
        },

        closeRejectConfirmation() {
            this.$refs.rejectApprovalConfirmation.$emit('close');
        },

        confirmRejection() {
            this.reject(this.selectedOrganizationIdForRejection);
            this.selectedOrganizationIdForRejection = null;
            this.closeRejectConfirmation();
        },

        createNewOrganization() {
            this.$router
                .push({
                    name: this.$root.findRouteName('organization-new'),
                    query: this.$route.query,
                })
                .catch(navigationFailure);
        },

        updatePredefinedFilter(propertyValue) {
            this.organizations = null;

            this.forcedFilter = _cloneDeep(this.predefinedFilters[propertyValue]);
            this.persistFilter('organizationForced', this.forcedFilter);

            // assmeble suggested filter
            if (this.predefinedOptionalFilters[propertyValue]) {
                this.filter = _cloneDeep(this.predefinedOptionalFilters[propertyValue]);
                this.persistFilter('organization', this.filter, this.defaultFilter);
            }

            // we triggered the filter, so do not assmemble filters back to state
            skipReAssembleFilter = true;

            this.updateActivePredefinedFilter();
            this.refreshList();
        },

        pageNumberUpdated(number) {
            this.$eventHub.$emit('pageActionsScrollTop');
            this.filter.page = number;
            this.refreshList();
        },

        isSupplierOrganization(organization) {
            // TODO: check ability for viewing the factory button
            return organization.types.includes('supplier');
        },

        goToFactoryList(organization) {
            this.$router
                .push({
                    name: this.$root.findRouteName('admin-factory-management'),
                    query: this.$route.query,
                    params: {
                        supplierOrganizationId: organization.id,
                    },
                })
                .catch(navigationFailure);
        },

        goToCertificateList(organization) {
            this.$router
                .push({
                    name: this.$root.findRouteName('admin-certificate-management'),
                    query: this.$route.query,
                    params: {
                        organizationId: organization.id,
                    },
                })
                .catch(navigationFailure);
        },

        goToExternalLink(url) {
            window.open(url, '_blank');
        },

        menuListeners(organization) {
            const listeners = {
                'context-menu-factory-list': () => this.goToFactoryList(organization),
                'context-menu-certificate-list': () => this.goToCertificateList(organization),
            };

            organization.externalLinks.forEach((link, index) => {
                listeners[`context-menu-external-link-${index}`] = () => this.goToExternalLink(link.url);
            });

            return listeners;
        },

        menuActions(organization) {
            const options = [];

            if (this.isSupplierOrganization(organization)) {
                options.push({
                    id: 'factory-list',
                    icon: SfFacilitiesIcon,
                    label: this.$t('pages.organization.list.factory'),
                });
            }

            if (organization.types.some(type => ['supplier', 'carrier'].includes(type))) {
                options.push({
                    id: 'certificate-list',
                    icon: SfDocumentsIcon,
                    label: this.$t('pages.settingsV2.titles.certificateManagement'),
                });
            }

            if (organization.externalLinks) {
                options.push(
                    ...organization.externalLinks.map((link, index) => ({
                        id: `external-link-${index}`,
                        icon: SfExternalLinkIcon,
                        label: link.label,
                    }))
                );
            }

            return options;
        },
    },
};
</script>

<style lang="scss">
.organization-type-selection__button {
    height: 0;
    padding: 100% 15px 0;
    background-color: $color-white;
    box-shadow: $boxShadow-bottomShort;
    position: relative;
}

.organization-type-selection__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40%;
    width: 100%;
    transform: translate(-50%, -50%);
}

.organization-type-selection__label {
    position: absolute;
    left: 50%;
    bottom: 10%;
    font-size: 18px;
    transform: translateX(-50%);
}

.organization-list__types {
    > * {
        margin: 3px;
    }
}

.organization-list__advanced-filter {
    background-color: $color-white;
    box-shadow: $boxShadow-bottomShort;
    margin-bottom: 30px;
}

.organization-list__actions {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 30px;

    @media screen and (max-width: $layout-desktop-max) {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.organization-list__bottom-pagination {
    margin-top: 30px;
}
</style>
