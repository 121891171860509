import { Button, Textarea } from '@schuettflix/react-components';
import { useState } from 'react';
import { extractDataFromUrl } from '../utils/link';
function encode(raw) {
    try {
        return encodeURI(JSON.stringify(JSON.parse(raw)));
    }
    catch {
        return 'Invalid JSON';
    }
}
const demoLink = {
    organizations: [],
    users: [],
    constructionProjects: [],
};
export function UrlCreator() {
    const [value, setValue] = useState(JSON.stringify(extractDataFromUrl() ?? demoLink, null, 2));
    const encoded = encode(value);
    const rows = 20;
    return (<div className="flex flex-col gap-2 p-4">
            <div className="grid grid-cols-2 gap-2">
                <Textarea rows={rows} label="JSON" value={value} onChange={e => setValue(e.target.value)}/>
                <Textarea rows={rows} label="Encoded" readOnly disabled value={encoded}/>
            </div>
            <Button size="sm" label="Save to URL and reload" type="button" onClick={() => {
            window.location.href = `/#/magic?data=${encoded}`;
            window.location.reload();
        }}/>
        </div>);
}
