import { asVueComponent } from '@/reactBridge/asVueComponent';
import { TransportListDesktop } from '@/pages/Order/TransportList/components/TransportListDesktop';
import { TransportListMobile } from '@/pages/Order/TransportList/components/TransportListMobile';
import { useTransportsScopedByOrder } from '@schuettflix/app-transport';
import { mapToTransportListItems } from '@/pages/Order/TransportList/utils/mapToTransportListItems';
import { useAppDimension } from '@/reactBridge/useAppDimension';
export const _TransportList = ({ orderId }) => {
    const { data } = useTransportsScopedByOrder({ orderId });
    const { isDesktop } = useAppDimension();
    const transports = mapToTransportListItems(data);
    return isDesktop ? (<TransportListDesktop transports={transports}/>) : (<TransportListMobile transports={transports}/>);
};
export const TransportList = asVueComponent(_TransportList);
