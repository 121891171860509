import { useUserById, useUserBySalesForceId } from '../../hooks/api';
export function useCheckExistingUser(id, salesForceId) {
    const userById = useUserById(id);
    const userBySalesForceId = useUserBySalesForceId(salesForceId);
    /**
     * If salesForce id and user id point to different users, this should cause a warning/error
     */
    const idMismatch = userById.data && userBySalesForceId.data && userById.data.id !== userBySalesForceId.data.id;
    const isNew = userById.data === null && userBySalesForceId.data === null;
    const user = userById.data ?? userBySalesForceId.data ?? null;
    const isLoading = userById.isLoading || userBySalesForceId.isLoading;
    return { isNew, idMismatch, user, isLoading };
}
