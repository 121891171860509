import { getAllLanguagesInLocale } from '@/i18n/languages';
import { userInfoQuery } from '@/reactBridge/queryClient';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
export function useOwnLocale() {
    const me = useQuery(userInfoQuery);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- no types available and i can't be bothered RN
    // @ts-ignore
    const locale = me.data?.user?.locale;
    return locale ?? null;
}
export function useAvailableLocales() {
    const ownLocale = useOwnLocale();
    const locales = useMemo(() => {
        if (!ownLocale)
            return [];
        return getAllLanguagesInLocale(ownLocale);
    }, [ownLocale]);
    return locales;
}
