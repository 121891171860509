import Ability from '@/services/Api/Ability';
import Authenticator from '@/services/Api/Authenticator';
import NetsuiteApi from '@/services/Api/NetSuite';
import OrganizationApi from '@/services/Api/Organization';
import { billingAddressSettingsApi } from '@/services/Api/Platform/BillingAddressSettings';
import UserApi from '@/services/Api/Platform/User';
import PlatformApi from '@/services/Api/Platform/Utils';
import ProjectApi from '@/services/Api/Project';
import UserV2 from '@/services/Api/UserV2';
import { MutationCache, QueryCache } from '@tanstack/query-core';
import { QueryClient } from '@tanstack/react-query';
// Mutation and Query caches are shared between Vue and React
export const mutationCache = new MutationCache();
export const queryCache = new QueryCache();
// This query client is only used for React components
export const queryClient = new QueryClient({
    mutationCache,
    queryCache,
});
export const platformInfoQuery = {
    queryKey: ['platform', 'info'],
    queryFn: () => PlatformApi.getPlatformInfo(),
    cacheTime: Infinity,
};
export const abilitiesQuery = {
    queryKey: ['abilities'],
    queryFn: () => Ability.getAll(),
};
export const userInfoQuery = {
    queryKey: ['user', 'info'],
    queryFn: () => Authenticator.requestUserInfo(),
};
export const projectsKeys = {
    namespace: ['projects'],
    byId: (id) => [...projectsKeys.namespace, id],
};
export const netsuitePaymentTermsQuery = {
    queryKey: ['organization', 'paymentTerms'],
    queryFn: () => NetsuiteApi.getNetSuitePaymentTerms(),
};
export const allUsersQuery = {
    queryKey: ['user', 'all'],
    queryFn: () => UserApi.getAll(),
};
export const billingAddressSettingsQuery = {
    queryKey: ['billingAddress', 'settings'],
    queryFn: () => billingAddressSettingsApi.getBillingAddressSettings(),
};
export function organizationByIdQuery(id) {
    return {
        queryKey: ['organization', 'byId', id],
        queryFn: async () => {
            if (id === undefined)
                return null;
            const org = await OrganizationApi.getOneById(id);
            return org;
        },
    };
}
export function organizationPermissionsQuery(organizationId) {
    return {
        queryKey: ['organization', organizationId, 'permission-definition'],
        queryFn: () => OrganizationApi.getAvailablePermissions(organizationId),
    };
}
export function organizationSearchQuery(search) {
    const filter = { search, perPage: 5 };
    return {
        queryKey: ['organization', 'filter', filter],
        queryFn: async () => {
            if (!search)
                return [];
            const response = await OrganizationApi.filter(filter);
            const orgs = response.items.length ? response.items : null;
            return orgs;
        },
    };
}
export function organizationBySalesforceIdQuery(salesforceAccountId) {
    const filter = { salesforceAccountId };
    return {
        queryKey: ['organization', 'filter', filter],
        queryFn: async () => {
            if (!filter.salesforceAccountId)
                return null;
            const response = await OrganizationApi.filter(filter);
            const org = response.items.length ? response.items[0] : null;
            return org;
        },
    };
}
export function projectByIdQuery(id) {
    return {
        queryKey: projectsKeys.byId(id),
        queryFn: () => ProjectApi.getOneById(id),
    };
}
export function userById(id) {
    return {
        queryKey: ['user', 'byId', id],
        queryFn: async () => {
            if (!id)
                return null;
            const user = await UserApi.getOneById(id);
            return user;
        },
    };
}
export function userBySalesForceId(salesforceContactId) {
    const filter = { salesforceContactId };
    return {
        queryKey: ['user', 'filter', filter],
        queryFn: async () => {
            if (!filter.salesforceContactId)
                return null;
            const response = await UserV2.filter(filter);
            const user = response.items.length ? response.items[0] : null;
            return user;
        },
    };
}
export async function prefetchQueries() {
    await Promise.allSettled([
        queryClient.prefetchQuery(abilitiesQuery),
        queryClient.prefetchQuery(userInfoQuery),
        queryClient.prefetchQuery(platformInfoQuery),
    ]);
}
