import { allUsersQuery, billingAddressSettingsQuery, netsuitePaymentTermsQuery, organizationByIdQuery, organizationBySalesforceIdQuery, userById, userBySalesForceId, } from '@/reactBridge/queryClient';
import Organization from '@/services/Api/Organization';
import UserV2 from '@/services/Api/UserV2';
import { useMutation, useQuery } from '@tanstack/react-query';
export function useAllEmployees() {
    return useQuery(allUsersQuery);
}
export function useBillingAddressSettings() {
    return useQuery(billingAddressSettingsQuery);
}
export function useOrganizationById(id) {
    return useQuery(organizationByIdQuery(id));
}
export function useOrganizationBySalesForceId(salesforceAccountId) {
    return useQuery(organizationBySalesforceIdQuery(salesforceAccountId));
}
export function useOrganizationSave() {
    return useMutation({
        mutationFn: (org) => Organization.save(org),
    });
}
export function useNetsuitePaymentTerms() {
    return useQuery(netsuitePaymentTermsQuery);
}
export function useUserBySalesForceId(salesForceId) {
    return useQuery(userBySalesForceId(salesForceId));
}
export function useUserById(id) {
    return useQuery(userById(id));
}
export function useUserSave() {
    return useMutation({
        mutationFn: (user) => UserV2.save(user),
    });
}
