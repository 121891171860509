import { Card } from '@/pages/Magic/components/Card';
import { organizationPermissionsQuery } from '@/reactBridge/queryClient';
import { objToArr } from '@/pages/Magic/utils/data';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '@/pages/Magic/components/Loader';
import { useTranslation } from 'react-i18next';
export function SettingsPreview(props) {
    const { t } = useTranslation();
    const values = props.form.getValues();
    const orgPermissions = useQuery(organizationPermissionsQuery(values.organization.id));
    // const all = orgPermissions.data ? Object.values(orgPermissions.data).flatMap(s => objToArr(s)) : null;
    if (orgPermissions.isLoading)
        return <Loader />;
    return (<div className="flex flex-col gap-2">
            <Card title={t('pages.magic.userForm.permissions.title')} onEdit={props.onEdit}>
                <div className="flex flex-col gap-6">
                    {orgPermissions.data &&
            Object.entries(orgPermissions.data).map(([key, permissions], i) => {
                const activePermissions = objToArr(permissions).filter(p => values.permissions.includes(p.key));
                return (<div key={key} className="contents">
                                    {i > 0 && <hr className="border-divider"/>}
                                    <div className="flex flex-col gap-2">
                                        <div className="font-copy-md">
                                            {t(`pages.magic.user.userForm.permissions.${key}`)}
                                        </div>

                                        {activePermissions.length > 0 ? (<ul className="flex flex-col">
                                                {activePermissions.map(p => (<li key={p.key} className="font-copy-sm text-subdued">
                                                        {p.title}
                                                    </li>))}
                                            </ul>) : (<div>－</div>)}
                                    </div>
                                </div>);
            })}
                </div>
            </Card>
        </div>);
}
