import { PdfIcon } from '@schuettflix/planum-icons-react';
import { Link } from '@schuettflix/react-components';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
export function MandateReference(props) {
    const { t } = useTranslation();
    const info = {
        acceptedBy: props.mandateReference.acceptedByUserName,
        date: format(new Date(props.mandateReference.acceptedAt), 'DD.MM.YYYY'),
        time: format(new Date(props.mandateReference.acceptedAt), 'H:mm'),
    };
    const mandateAcceptedInfo = t('components.organizationForm.paymentInfo.mandateReferenceTime', info);
    const downloadUrl = props.mandateReference.document?.url ?? null;
    return (<div className="flex flex-col gap-1">
            <div className="font-copy-md-strong mb-2">
                {t('components.organizationForm.paymentInfo.mandateReference')}
            </div>

            <p>
                {props.mandateReference.name}
                <br />
                <span className="font-copy-sm">{mandateAcceptedInfo}</span>
            </p>

            <div className="flex flex-col gap-2 bg-light-gray-100 p-4">
                <div className="font-copy-sm-strong">{t('components.organizationForm.paymentInfo.debit')}</div>
                <p>{t('components.organizationForm.paymentInfo.debitHint')}</p>

                {downloadUrl && (<Link href={downloadUrl} prefix={<PdfIcon size={32}/>} label={t('components.organizationForm.paymentInfo.pdfDownloadHint')}/>)}
            </div>
        </div>);
}
