import { ProductSummary } from './ProductSummary';
import { useCategoryLabelByGroupType } from '../hooks/useResolveCategoryLabelByGroupType';
export function Transactions(props) {
    const { resolveCategoryLabelByGroupType } = useCategoryLabelByGroupType();
    return (<div className="flex flex-col gap-6">
            {props.transactions.map(transaction => (<div key={transaction.productId}>
                    <div className="font-copy-sm-strong">
                        {resolveCategoryLabelByGroupType(transaction.productCategory, transaction.groupType)}
                    </div>
                    <ProductSummary transaction={transaction} financeSettings={props.financeSettings}/>
                </div>))}
        </div>);
}
