import { Modal, ModalTrigger } from '@schuettflix/planum-react';
import { Link, LoadingSpinner } from '@schuettflix/react-components';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAbility } from '@/reactBridge/useAbility';
export function GenericPositionPrices({ children }) {
    const { t } = useTranslation();
    const [openModalPricesSummary, setOpenModalPricesSummary] = useState(false);
    const canSeePrices = useAbility('seePrices');
    if (!canSeePrices) {
        return null;
    }
    return (<div className="mt-8 flex max-w-screen-lg flex-col gap-2">
            <Link label={t('pages.genericPosition.prices-and-services')} className="font-copy-md-strong" onClick={() => setOpenModalPricesSummary(true)}/>

            <ModalTrigger isOpen={openModalPricesSummary} onOpenChange={() => setOpenModalPricesSummary(!openModalPricesSummary)}>
                <Modal className="max-h-18" isDismissable>
                    {({ close }) => (<>
                            <Modal.Heading onAbort={close}>
                                {t('pages.genericPosition.prices-and-services')}
                            </Modal.Heading>
                            <Modal.Content>
                                <Suspense fallback={<div className="flex justify-center p-8">
                                            <LoadingSpinner block/>
                                        </div>}>
                                    {children}
                                </Suspense>
                            </Modal.Content>
                        </>)}
                </Modal>
            </ModalTrigger>
        </div>);
}
