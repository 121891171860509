import { organizationSearchQuery } from '@/reactBridge/queryClient';
import { Combobox } from '@schuettflix/react-components';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
export function SelectOrganization(props) {
    const [needle, setNeedle] = useState('');
    const orgs = useQuery(organizationSearchQuery(needle));
    const data = props.availableOrganizations ?? orgs.data ?? [];
    const options = data.map(org => ({
        label: org.name,
        value: org.id,
        description: org.id.toString().padStart(7, '0'),
    }));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Omitting options from the props messes up typescript
    // @ts-ignore
    return <Combobox {...props} onInputChange={setNeedle} options={options} multiple={false}/>;
}
