import { partialByPath } from './partial';
/**
 * Checks if the errors object contains errors for the given paths.
 */
export function hasErrors(errors, paths) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const relevantErrors = partialByPath(errors, paths.map(field => `${field}.message`));
    return Object.keys(relevantErrors);
}
