import React from 'react';
import clsx from 'clsx';
import { ExternalLinkIcon } from '@schuettflix/icons-react';
export const MenuTileExternal = ({ className, to, label, description, icon: Icon, dataTest }) => (<a className={clsx('flex min-h-18 items-center p-4', className)} data-test={dataTest} href={to} target="_blank" rel="noreferrer">
        <Icon />
        <div className="ml-4 flex-1">
            <h2 className="font-copy-md mb-1">{label}</h2>
            {description && <p className="font-copy-sm text-subdued">{description}</p>}
        </div>
        <ExternalLinkIcon size="xs"/>
    </a>);
