<template>
    <div>
        <div class="project-position-view__filter-block mb-8">
            <RoundedTabNavigation
                :value="orderListing.activeRestriction"
                :tabs="orderListing.restrictionsLabelSet"
                @input="orderListing.selectRestriction($event)"
            />
            <Card spaceless>
                <FilterBox
                    v-model="orderListing.filter"
                    :default-filter="orderListing.defaultFilter"
                    :forced-filter="orderListing.restriction"
                    :endpoint="orderListing.endpoint"
                    inline-mode
                    no-padding
                    @update="orderListing.refreshList(false, true, true)"
                >
                    <template #default="filterScope">
                        <FilterSortPagination
                            :result="orderListing.result"
                            :filter="filterScope.filter"
                            :sort-options="orderListing.sortOptions"
                            :option-label-renderer="value => $t(`order.sorting.${value}`)"
                            show-refresh
                            spaceless
                            :loading="orderListing.isLoading"
                            @refresh="orderListing.refreshList(false, true, true)"
                            @pageNumberUpdated="orderListing.updatePageNumber($event)"
                        >
                            <div v-if="$root.isDesktop" />
                        </FilterSortPagination>
                    </template>
                </FilterBox>
            </Card>
        </div>

        <div class="mb-4 mt-12 pl-4 lg:pl-0">
            <span class="font-copy-md-strong">
                {{ $tc('pages.project.position.orders.headline', orderListing.resultCount) }}
            </span>
        </div>

        <template v-if="orderListing.resultCount">
            <Card v-for="order in orderListing.resultItems" :key="order.id" :spaceless-x="$root.isDesktop">
                <OrderItemBlock :order-view="order" @click="selectOrder(order)" />
            </Card>
            <Card spaceless>
                <Pagination
                    align-right
                    :result="orderListing.result"
                    @pageNumberUpdated="orderListing.updatePageNumber($event)"
                />
            </Card>
        </template>
        <Hint v-else center transparent>
            {{ $t('order.list.noResults') }}
        </Hint>
    </div>
</template>

<script>
import FilterableListing from '@/services/FilterableListing/FilterableListing';
import OrderApi from '@/services/Api/Order';
import { mapGetters } from 'vuex';

import routeContext from '@/plugins/mixins/routeContext';
import eventHubMixin from '@/plugins/mixins/eventHubMixin';
import statefulMixin from '@/plugins/mixins/statefulMixin';

import Card from '@/components/Layout/Card';
import FilterBox from '@/components/Filter/FilterBox';
import FilterSortPagination from '@/components/Filter/FilterSortPagination';
import Hint from '@/components/Typography/Hint';
import OrderItemBlock from '@/components/List/OrderItemBlock';
import Pagination from '@/components/Pagination';
import RoundedTabNavigation from '@/components/Tab/RoundedTabNavigation';

import OrderItemBlockView from '@/components/List/OrderItemBlockView';
import { CONSTRUCTION_PROJECT_ADMIN_POSITION_DETAILS_ROUTE } from '@/modules/constructionProjects/pages/routes';
import { useLd } from '@/services/LaunchDarkly';

export default {
    name: 'GenericPositionOrdersList',
    components: {
        Card,
        FilterBox,
        FilterSortPagination,
        Hint,
        OrderItemBlock,
        Pagination,
        RoundedTabNavigation,
    },
    mixins: [eventHubMixin, statefulMixin, routeContext],
    props: {
        positionId: {
            type: [Number, String],
            default: null,
        },
    },
    setup() {
        const isGenericOrderDetailsEnabled = useLd('monolith-display-generic-order-details');

        return {
            isGenericOrderDetailsEnabled,
        };
    },
    data() {
        const orderListing = new FilterableListing({
            namespace: 'genericPositionOrder',
            endpoint: OrderApi,
            defaultFilter: {
                page: 1,
                perPage: 25,
                sortBy: 'created',
                sortDirection: 'desc',
            },
            defaultRestriction: {
                genericPosition: this.positionId,
                projectPosition: undefined,
            },
            sortOptions: ['created', 'orderNumber', 'client', 'supplier', 'invoiceStatus'],
            restrictionLabelRenderer: (restrictionKey, count) => {
                return this.$t(`pages.project.position.orders.tab.label.${restrictionKey}`, { count: count });
            },
            transformCallback: item => OrderItemBlockView.create(item),
        });

        if (this.$can('haveProjectManagement')) {
            orderListing.addRestriction('new', {
                status: 'new',
            });
            orderListing.addRestriction('in_progress', {
                status: 'in_progress',
            });
        } else {
            orderListing.addRestriction('in_progress', {
                status: ['new', 'in_progress'],
            });
        }

        orderListing.addRestriction('closed', {
            status: 'closed',
        });

        orderListing.addRestriction('canceled', {
            status: 'canceled',
        });

        return {
            orderListing,
        };
    },
    computed: {
        ...mapGetters('user', ['isPlatformAdministrator']),
    },
    watch: {
        $route() {
            this.orderListing.onRouteChanged();
        },
    },
    created() {
        this.orderListing.pairComponent(this);
        this.orderListing.refreshList(true);
    },
    methods: {
        selectOrder(order) {
            if (!order.isGenericOrder || !this.isGenericOrderDetailsEnabled) {
                return;
            }

            this.$router
                .push({
                    name: this.$root.findRouteName(
                        `${CONSTRUCTION_PROJECT_ADMIN_POSITION_DETAILS_ROUTE}__generic-order-details`
                    ),
                    params: { orderId: order.genericOrderId },
                    query: this.$route.query,
                })
                .catch(() => {});
        },
    },
};
</script>
