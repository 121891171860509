<template>
    <nav class="nav" :class="{ 'nav--collapsed': navigationCollapsed, 'nav--overlay-open': overlayItem }">
        <div v-if="$root.isDesktop" class="nav__header">
            <router-link :to="{ name: 'home' }" class="nav__header-link">
                <SchuettflixLogo v-if="!navigationCollapsed" class="img-responsive" />
                <AppIcon v-else class="img-responsive" />
            </router-link>
        </div>

        <div data-test="nav-items" class="nav__content" :class="{ 'nav__content--overlay-open': overlayItem }">
            <NavigationRenderer
                v-for="(item, i) in navigationItems"
                :key="i"
                :item="item"
                :overlay-item="overlayItem"
                @subnavigation="openSubnavigation($event)"
            />
            <NavigationOverlay
                v-if="overlayItem"
                :item="overlayItem"
                @selectnav="closeSubnavigation()"
                @close="closeSubnavigation()"
            />
        </div>

        <div v-if="$root.isDesktop" class="nav__toggle">
            <button
                data-test="nav-toggle-button"
                class="nav__toggle-button flex items-center justify-center bg-surface-active"
                @click="toggleNavigationCollapsed"
            >
                <ArrowIcon
                    class="nav__toggle-arrow icon--white"
                    :class="{ 'icon--rotate-180': !navigationCollapsed }"
                    height="14"
                />
            </button>
        </div>
    </nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import { useFeatureFlag } from '@/services/FeatureFlags/useFeatureFlags.ts';

import { useLegalTerms } from '@/services/LegalTerms/useLegalTerms';
import { useLd } from '@/services/LaunchDarkly';

import NavigationRenderer from './Navigation/NavigationRenderer';
import NavigationOverlay from './Navigation/NavigationOverlay';

import SchuettflixLogo from '@/assets/schuettflix_logo.svg';
import AppIcon from '@/assets/icons/navigation.v2/app_icon.svg';
import ArrowIcon from '@/assets/icons/regular/arrow-stripeless.svg';
import { CONSTRUCTION_PROJECT_LIST_ROUTE } from '@/constructionProjects/constants';
import { CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE } from '@/modules/constructionProjects/pages/routes';

export default {
    name: 'NavigationComponent',
    components: {
        NavigationRenderer,
        NavigationOverlay,

        SchuettflixLogo,
        AppIcon,
        ArrowIcon,
    },
    setup() {
        const { selectCreditNoteDocKey } = useLegalTerms();

        const { isEnabled: isFeatureFlagDisposalEnabled } = useFeatureFlag('disposal_project_order');
        const isManualBillingUIEnabled = useLd('new-manual-billing-ui');
        const isOrganizationAccountingEnabled = useLd('accounting-ui-for-organization-admins');

        return {
            selectCreditNoteDocKey,
            isFeatureFlagDisposalEnabled,
            isManualBillingUIEnabled,
            isOrganizationAccountingEnabled,
        };
    },
    data() {
        return {
            overlayItem: null,
        };
    },
    computed: {
        ...mapState('session', ['navigationCollapsed']),

        // eslint-disable-next-line complexity
        navigationItems() {
            const items = [
                {
                    type: 'link',
                    route: 'home',
                    exact: true,
                    icon: 'uebersicht',
                    title: 'overview',
                },
            ];

            const orderGroup = {
                type: 'group',
                title: 'build',
                route: 'order',
                icon: 'bestellen',
                items: [],
            };

            if (this.$can('haveClientOrders')) {
                if (this.$can('listProjects')) {
                    orderGroup.items.push({
                        type: 'link',
                        route: CONSTRUCTION_PROJECT_LIST_ROUTE,
                        icon: 'projekte',
                        title: 'constructionProjects',
                    });
                }
                if (this.$can('listOrders')) {
                    orderGroup.items.push({
                        type: 'link',
                        route: 'order__order-list',
                        icon: 'bestellungen',
                        title: 'order-orders',
                        // count: 4,
                    });

                    orderGroup.items.push({
                        type: 'link',
                        route: 'order__transport-list',
                        icon: 'transporte',
                        title: 'order-transports',
                        // count: 150,
                    });
                }
            }

            orderGroup.items.length > 0 && items.push(orderGroup);

            // Bestellmengen
            const materialsGroup = {
                type: 'group',
                title: 'materials',
                route: 'materials',
                icon: 'baustoffe',
                items: [],
            };

            if (this.$can('listSupplierProjectPositions')) {
                materialsGroup.items.push({
                    type: 'link',
                    route: 'materials__project-position-list',
                    icon: 'projekte',
                    title: 'projectsSupplier',
                });
            }

            if (this.$can('listSupplierProductCapacity')) {
                materialsGroup.items.push({
                    type: 'link',
                    route: 'materials__order-quantity',
                    icon: 'bestellmengen',
                    title: 'materials-order-quantity',
                });
            }

            if (this.$can('listSupplierTransports')) {
                materialsGroup.items.push({
                    type: 'link',
                    route: 'materials__transport-list',
                    icon: 'auftraege',
                    title: 'materials-transports',
                });
            }

            if (this.$can('haveCourtList')) {
                materialsGroup.items.push({
                    type: 'link',
                    route: 'materials__court-list',
                    icon: 'meine_beladungen',
                    title: 'materials-court-list',
                    hideIconExpanded: true,
                    plusIcon: true,
                });
            }

            materialsGroup.items.length > 0 && items.push(materialsGroup);

            // logistics
            const logisticsGroup = {
                type: 'group',
                title: 'logistics',
                route: 'logistics',
                icon: 'logistik',
                items: [],
            };

            if (this.$can('listCarrierProjectPositions')) {
                logisticsGroup.items.push({
                    type: 'link',
                    route: 'logistics__project-position-list',
                    icon: 'projekte',
                    title: 'projectsCarrier',
                });
            }

            if (this.$can('haveTransportHub')) {
                logisticsGroup.items.push({
                    type: 'link',
                    route: 'logistics__transport-hub',
                    icon: 'auftragsboerse',
                    title: 'logistics-transport-hub',
                });
            }

            if (
                this.$can('listCarrierDeliveryTransports') ||
                this.$can('listCarrierShipmentTransports') ||
                (this.$can('listCarrierDisposalTransports') && this.isFeatureFlagDisposalEnabled)
            ) {
                logisticsGroup.items.push({
                    type: 'link',
                    route: 'logistics__transport-list',
                    icon: 'auftraege',
                    title: 'logistics-transports',
                });
            }

            if (this.$can('haveLogBook')) {
                logisticsGroup.items.push({
                    type: 'link',
                    route: 'logistics__logbook',
                    icon: 'fahrtenbuch',
                    title: 'logistics-logbook',
                });
            }

            if (this.$can('listActiveTrips')) {
                logisticsGroup.items.push({
                    type: 'link',
                    route: 'logistics__active-trips',
                    icon: 'meine_fahrten',
                    title: 'logistics-my-trips',
                    hideIconExpanded: true,
                });
            }

            if (this.$can('listActivePickupTrips')) {
                logisticsGroup.items.push({
                    type: 'link',
                    route: 'logistics__pickup-active-trips',
                    icon: 'meine_abholungen',
                    title: 'logistics-pickups',
                    hideIconExpanded: true,
                    plusIcon: true,
                });
            }

            logisticsGroup.items.length > 0 && items.push(logisticsGroup);

            const orderManagementGroup = {
                type: 'group',
                title: 'order-management',
                route: 'order-management',
                icon: 'auftragsmanagement',
                items: [],
            };

            if (this.$can('haveProjectManagement')) {
                orderManagementGroup.items.push({
                    type: 'link',
                    route: CONSTRUCTION_PROJECT_ADMIN_LIST_ROUTE,
                    icon: 'projekte',
                    title: 'projects',
                });
            }

            if (this.$can('havePlatformOrderManagement')) {
                orderManagementGroup.items.push({
                    type: 'link',
                    route: 'order-management__order-list',
                    icon: 'bestellungen',
                    title: 'order-management-orders',
                });
            }

            if (this.$can('havePlatformTransportManagement')) {
                orderManagementGroup.items.push({
                    type: 'link',
                    route: 'order-management__transport-hub',
                    icon: 'auftraege',
                    title: 'order-management-transports',
                });
            }

            orderManagementGroup.items.length > 0 && items.push(orderManagementGroup);

            const accountingGroup = {
                type: 'group',
                title: 'accounting',
                route: 'accounting',
                icon: 'buchhaltung',
                items: [],
            };

            if (this.$can('netSuitePaymentTermsEligibilityManagement')) {
                accountingGroup.items.push({
                    type: 'link',
                    route: 'accounting__payment-terms-selection',
                    icon: 'einstellungen',
                    title: 'accounting-paymentTerms',
                });
            }

            if (this.isManualBillingUIEnabled && this.$can('listTransactionLineItems')) {
                accountingGroup.items.push({
                    type: 'link',
                    route: 'accounting__manual-billing',
                    icon: 'rechnungen',
                    title: 'accounting-manualBilling',
                });
            }

            if (this.$can('listAccountingDocuments')) {
                accountingGroup.items.push({
                    type: 'link',
                    route: 'accounting__ui',
                    icon: 'rechnungen',
                    title: 'accounting-accountingUI',
                });
            }

            accountingGroup.items.length > 0 && items.push(accountingGroup);

            const managementGroup = {
                type: 'group',
                title: 'management',
                route: 'management',
                icon: 'verwaltung',
                items: [],
            };

            if (this.$can('haveOrganizationManagement')) {
                managementGroup.items.push({
                    type: 'link',
                    route: 'management__organization-list',
                    icon: 'organisationen',
                    title: 'management-organisations',
                });
            }

            if (this.$can('haveUserManagement') && this.$can('listAllUsers')) {
                managementGroup.items.push({
                    type: 'link',
                    route: 'management__user-management',
                    icon: 'nutzer',
                    title: 'management-users',
                });
            }

            if (this.$can('listProductCatalog')) {
                managementGroup.items.push({
                    type: 'link',
                    route: 'management__product-management',
                    icon: 'produktkatalog',
                    title: 'management-product-catalog',
                });
            }

            if (this.$can('havePartnerSearch')) {
                managementGroup.items.push({
                    type: 'link',
                    route: 'management__partner-search',
                    icon: 'partnersuche',
                    title: 'management-partner-search',
                });
            }

            if (this.$can('haveReceipts')) {
                if (this.isOrganizationAccountingEnabled) {
                    managementGroup.items.push({
                        type: 'link',
                        route: 'management__accounting',
                        icon: 'dokumente',
                        title: this.selectCreditNoteDocKey({
                            creditNote: `management-documents-title.creditNote`,
                            selfBilling: `management-documents-title.selfBilling`,
                        }),
                    });
                } else {
                    managementGroup.items.push({
                        type: 'link',
                        route: 'management__documents',
                        icon: 'dokumente',
                        title: this.selectCreditNoteDocKey({
                            creditNote: `management-documents-title.creditNote`,
                            selfBilling: `management-documents-title.selfBilling`,
                        }),
                    });
                }
            }

            if (this.$can('listSupplierProducts') || this.$can('viewSupplierWasteProducts')) {
                let navigationTitle = 'management-products';
                if (this.$can('listSupplierProducts') && !this.$can('viewSupplierWasteProducts')) {
                    navigationTitle = 'management-products-supplier';
                }
                if (!this.$can('listSupplierProducts') && this.$can('viewSupplierWasteProducts')) {
                    navigationTitle = 'management-products-waste';
                }
                managementGroup.items.push({
                    type: 'link',
                    route: 'management__supplier-products',
                    icon: 'produkte',
                    title: navigationTitle,
                });
            }

            if (this.$can('haveReports')) {
                managementGroup.items.push({
                    type: 'link',
                    route: 'management__reporting',
                    icon: 'auswertungen',
                    title: 'management-reporting',
                });
            }

            managementGroup.items.push({
                type: 'link',
                route: 'management__settings',
                icon: 'einstellungen',
                title: 'management-settings',
            });

            managementGroup.items.length > 0 && items.push(managementGroup);

            return items;
        },
    },
    methods: {
        ...mapMutations('session', ['toggleNavigationCollapsed']),

        openSubnavigation(item) {
            // NOTE: Disable toggle
            // if (this.overlayItem && _isEqual(item, this.overlayItem)) {
            //     this.overlayItem = null;
            // } else {
            this.overlayItem = item;
            // }
        },

        closeSubnavigation() {
            this.overlayItem = null;
        },
    },
};
</script>

<style lang="scss">
.nav {
    display: block;
    z-index: 1;

    @media screen and (min-width: $layout-desktop-min) {
        display: grid;
        grid-template-rows: var(--header-height) 1fr auto;
        grid-template-areas:
            'header'
            'content'
            'footer';
    }
}

.nav--overlay-open {
    z-index: 1001;
}

.nav__header {
    grid-area: header;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 40px;

    .nav--collapsed & {
        padding-left: 0;
        justify-content: center;
    }
}

.nav__header-link {
    display: block;
}

.nav__content {
    grid-area: content;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;

    @media screen and (max-width: $layout-desktop-max) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
        /* flex-flow: row nowrap; */
        padding: 0 2px;
        height: auto;
    }
}

.nav__content--overlay-open {
    z-index: 1001;
}

.nav__toggle {
    padding-bottom: 12px;
    grid-area: footer;
    display: flex;
    justify-content: flex-end;
}

.nav__toggle-button {
    font-size: inherit;
    padding: 0;
    margin: 0;
    border: 0;
    width: 36px;
    height: 36px;
    outline: none;
    cursor: pointer;
}
</style>
