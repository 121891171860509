import { CheckIcon, CloseIcon, LockIcon } from '@schuettflix/icons-react';
import { Accordion, Button, NumberField, ToggleSwitch } from '@schuettflix/react-components';
import { useTranslation } from 'react-i18next';
import { Loader } from '../../../components/Loader';
import { useDefinitions, useDefinitionsForOrg } from './definition';
function SettingEdit(props) {
    const { t } = useTranslation();
    switch (props.setting.type) {
        case 'bool':
            return (<>
                    <ToggleSwitch label={props.setting.label} checked={props.value} onChange={props.onChange}/>
                    <div className="flex flex-col gap-2">
                        <div className="font-copy-sm">
                            {t('pages.magic.organization.form.permissions.defaultValue')}
                        </div>
                        <div className="font-copy-md-strong">{props.setting.default ?? '-'}</div>
                    </div>
                </>);
        case 'int':
            return (<>
                    <NumberField label={props.setting.label} value={props.value} onChange={e => props.onChange(e.target.valueAsNumber)} step={1}/>
                    <div className="flex flex-col gap-2">
                        <div className="font-copy-sm">
                            {t('pages.magic.organization.form.permissions.defaultValue')}
                        </div>
                        <div className="font-copy-md-strong">{props.setting.default ?? '-'}</div>
                    </div>
                </>);
        case 'float':
            return (<>
                    <NumberField label={props.setting.label} value={props.value} onChange={e => {
                    props.onChange(parseInt(e.target.value, 10));
                }}/>
                    <div className="flex flex-col gap-2">
                        <div className="font-copy-sm">
                            {t('pages.magic.organization.form.permissions.defaultValue')}
                        </div>
                        <div className="font-copy-md-strong">{props.setting.default ?? '-'}</div>
                    </div>
                </>);
    }
}
export function Features(props) {
    const { t } = useTranslation();
    const definitions = useDefinitions();
    const types = props.form.watch('types');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore not proud, but ¯\_(ツ)_/¯
    const features = props.form.watch('features') ?? {};
    const { sections } = useDefinitionsForOrg(types);
    if (definitions.isLoading) {
        return <Loader />;
    }
    if (definitions.isError) {
        const errorMessage = definitions.error instanceof Error && definitions.error.toString();
        return (<div className="m-auto w-full p-2">
                <p>{errorMessage}</p>
            </div>);
    }
    return (<div className="flex flex-col gap-6 pt-2">
            {sections &&
            sections.map(section => {
                return (<div key={section.key}>
                            <h2 className="font-copy-lg-strong mb-1">{section.title}</h2>
                            <Accordion>
                                <Accordion.Item leadingIcon={section.isEnabled ? CheckIcon : CloseIcon} title={section.headline}>
                                    <p>{section.description}</p>
                                    {!section.isEnabled && (<div className="mt-2 flex justify-end">
                                            <Button variant="floating" label={t('pages.magic.organization.form.permissions.addFeature')} size="sm" type="button" onClick={() => props.form.setValue('types', [...types, section.key], {
                            shouldTouch: true,
                            shouldDirty: true,
                        })}/>
                                        </div>)}
                                </Accordion.Item>

                                {section.features.map(feature => (<Accordion.Item title={feature.title} key={feature.key} disabled={!section.isEnabled} leadingIcon={section.isEnabled
                            ? feature.key in features
                                ? CheckIcon
                                : CloseIcon
                            : LockIcon}>
                                        <p className="mb-1">{feature.description}</p>
                                        {feature.key in features ? (<div className="flex flex-col gap-4 bg-light-gray-200 p-4">
                                                <div className="font-copy-md-strong">
                                                    {t('pages.magic.organization.form.permissions.settings')}
                                                </div>
                                                {feature.settings.map(setting => (<div key={setting.key}>
                                                        <div className="font-copy-md-strong">{setting.title}</div>
                                                        <p>{setting.description}</p>
                                                        <div className="mt-1 grid grid-cols-2">
                                                            <SettingEdit setting={setting} value={features[feature.key][setting.key]} onChange={value => {
                                    features[feature.key][setting.key] = value;
                                    props.form.setValue('features', features);
                                }}/>
                                                        </div>
                                                    </div>))}
                                            </div>) : (<div className="flex justify-end">
                                                <Button label={t('pages.magic.organization.form.permissions.activate')} size="sm" variant="floating" onClick={() => {
                                // Fill the setting keys with empty values to the feature.
                                features[feature.key] = Object.fromEntries(feature.settings.map(setting => [setting.key, undefined]));
                                props.form.setValue('features', features);
                            }}/>
                                            </div>)}
                                    </Accordion.Item>))}
                            </Accordion>
                        </div>);
            })}
        </div>);
}
