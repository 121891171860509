import { createContext, useReducer } from 'react';
function defaultValues() {
    return {
        availableSteps: [],
        stepNumber: 0,
        title: '',
        magicData: null,
        savedOrganizations: [],
        savedUsers: [],
        savedConstructionProjects: [],
        prefilledUsers: [],
    };
}
export const MagicContext = createContext({
    state: defaultValues(),
    dispatch: () => { },
});
function deriveStep(state) {
    if (!state.magicData)
        return { availableSteps: [], stepNumber: 0, step: undefined };
    const stepOrder = ['organizations', 'users', 'constructionProjects'];
    // Count steps with at least one items in it
    const availableSteps = stepOrder.filter(step => state.magicData?.[step].length ?? Infinity > 0);
    const step = state.step === undefined ? availableSteps[0] : state.step;
    const stepNumber = availableSteps.indexOf(step) + 1;
    return { availableSteps, stepNumber, step };
}
export function useMagicContext() {
    function defaultReducer(state, action) {
        const newState = { ...state, ...action };
        return { ...newState, ...deriveStep(newState) };
    }
    const [state, dispatch] = useReducer(defaultReducer, defaultValues());
    return { state, dispatch };
}
export function getNextStep(state) {
    if (state.availableSteps.length === 0 || state.stepNumber === state.availableSteps.length)
        return 'end';
    return state.availableSteps[state.stepNumber + 1];
}
