import store from '@/store';
import { CONTEXT_CLIENT } from '@/constants/context';
import { CONSTRUCTION_PROJECT_CREATE_ROUTE, CONSTRUCTION_PROJECT_DETAILS_ROUTE, CONSTRUCTION_PROJECT_DOCUMENTS_ROUTE, CONSTRUCTION_PROJECT_LIST_ROUTE, CONSTRUCTION_PROJECT_ORDER_ROUTE, CONSTRUCTION_PROJECT_POSITIONS_ROUTE, CONSTRUCTION_PROJECT_POSITION_DETAILS_ROUTE, CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE, CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE, CONSTRUCTION_PROJECT_LOCATIONS_ROUTE, CONSTRUCTION_PROJECT_CLIENT_GENERIC_POSITION_DETAILS_ROUTE, } from '../constants';
import ConstructionProjectDetailsPage from './ConstructionProjectDetailsPage.vue';
import ConstructionProjectListPage from './ConstructionProjectListPage.vue';
import { ConstructionProjectOrderPage } from './ConstructionProjectOrderPage';
import ConstructionProjectSettingsPage from './ConstructionProjectSettingsPage.vue';
import { ConstructionProjectLocationsPage } from '@/constructionProjects/pages/ConstructionProjectLocationsPage';
import { ConstructionProjectPositionsPage, ConstructionProjectPositionDetailsPage, } from './ConstructionProjectPositions';
import ConstructionProjectDocumentsPage from './ConstructionProjectDocumentsPage.vue';
import { ConstructionProjectClientFlyout } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectClientFlyout';
import { ConstructionProjectClientEditFlyout } from '@/constructionProjects/pages/ConstructionProjectEdit/ConstructionProjectClientEditFlyout';
import ProjectGenericPositionClientDetailsPage from '@/modules/constructionProjects/pages/Client/ConstructionProjectClientGenericPositionDetailsPage/ConstructionProjectClientGenericPositionDetailsPage.vue';
import GenericOrderDetails from '@/pages/Order/components/GenericOrderDetails.vue';
import { routerInstance } from '@/utils/instances';
export const constructionProjectRoutes = [
    {
        path: '/order/construction-projects',
        name: CONSTRUCTION_PROJECT_LIST_ROUTE,
        component: ConstructionProjectListPage,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'create',
                name: CONSTRUCTION_PROJECT_CREATE_ROUTE,
                component: ConstructionProjectClientFlyout,
                meta: {
                    requiresAuth: true,
                },
            },
        ],
    },
    {
        path: '/order/construction-projects/:id',
        name: CONSTRUCTION_PROJECT_DETAILS_ROUTE,
        component: ConstructionProjectDetailsPage,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'settings',
                name: CONSTRUCTION_PROJECT_CLIENT_SETTINGS_ROUTE,
                component: ConstructionProjectSettingsPage,
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: 'edit',
                        name: CONSTRUCTION_PROJECT_CLIENT_SETTINGS_EDIT_ROUTE,
                        component: ConstructionProjectClientEditFlyout,
                        meta: {
                            requiresAuth: true,
                        },
                    },
                    {
                        path: 'locations',
                        name: CONSTRUCTION_PROJECT_LOCATIONS_ROUTE,
                        component: ConstructionProjectLocationsPage,
                        meta: {
                            requiresAuth: true,
                        },
                    },
                ],
            },
        ],
        beforeEnter(_to, from, next) {
            if (from.name) {
                // Only set the entrypoint when the users previous view was
                // either the home page or the construction project list
                const isEntrypoint = ['home', CONSTRUCTION_PROJECT_LIST_ROUTE].includes(from.name);
                if (isEntrypoint) {
                    store.commit('constructionProject/setDetailsPageEntryPoint', from.name);
                }
            }
            next();
        },
    },
    {
        path: '/order/construction-projects/:id/order',
        name: CONSTRUCTION_PROJECT_ORDER_ROUTE,
        component: ConstructionProjectOrderPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/order/construction-projects/:id/positions',
        name: CONSTRUCTION_PROJECT_POSITIONS_ROUTE,
        component: ConstructionProjectPositionsPage,
        meta: {
            requiredAbilities: ['listProjectPositions'],
        },
        props: route => ({
            parentRoute: {
                name: CONSTRUCTION_PROJECT_DETAILS_ROUTE,
                params: {
                    id: route.params.id,
                },
            },
        }),
    },
    {
        path: '/order/construction-projects/:id/generic-positions/:positionId',
        name: CONSTRUCTION_PROJECT_CLIENT_GENERIC_POSITION_DETAILS_ROUTE,
        component: ProjectGenericPositionClientDetailsPage,
        meta: {
            requiresAuth: true,
            context: CONTEXT_CLIENT,
        },
        props: route => {
            return {
                ...route.params,
                parentRoute: CONSTRUCTION_PROJECT_POSITIONS_ROUTE,
            };
        },
        children: [
            {
                path: 'generic-order/:orderId',
                name: 'generic-order-details',
                component: GenericOrderDetails,
                props: route => ({
                    orderId: route.params.orderId,
                    onPanelClose: () => {
                        const vueRouter = routerInstance.get();
                        if (vueRouter) {
                            vueRouter.push({
                                name: CONSTRUCTION_PROJECT_CLIENT_GENERIC_POSITION_DETAILS_ROUTE,
                                query: route.query,
                            });
                        }
                    },
                }),
            },
        ],
    },
    {
        path: '/order/construction-projects/:id/positions/:positionId',
        name: CONSTRUCTION_PROJECT_POSITION_DETAILS_ROUTE,
        component: ConstructionProjectPositionDetailsPage,
        meta: {
            context: CONTEXT_CLIENT,
            requiresAuth: true,
        },
        props: route => ({
            parentRoute: {
                name: CONSTRUCTION_PROJECT_POSITIONS_ROUTE,
                params: {
                    id: route.params.id,
                },
            },
            positionId: route.params.positionId,
        }),
        children: [
            {
                // @ts-expect-error this belongs to an abstraction in utils/router.js
                include: 'order-view',
            },
            {
                // @ts-expect-error this belongs to an abstraction in utils/router.js
                include: 'analysis',
            },
        ],
    },
    {
        path: '/order/construction-projects/:id/documents',
        name: CONSTRUCTION_PROJECT_DOCUMENTS_ROUTE,
        component: ConstructionProjectDocumentsPage,
        meta: {
            context: CONTEXT_CLIENT,
            requiresAuth: true,
        },
        props: route => ({
            parentRoute: {
                name: CONSTRUCTION_PROJECT_DETAILS_ROUTE,
                params: {
                    id: route.params.id,
                },
            },
        }),
    },
];
