import { flatten, unflatten } from 'flat';
function mapBackendErrorsToResolver(obj, filterByPath = null) {
    return unflatten(Object.fromEntries(Object.entries(flatten(obj, { safe: true }))
        .filter(([key]) => (filterByPath ? filterByPath.includes(key) : true))
        .map(([key, value]) => {
        if (key === '#') {
            key = 'root';
        }
        return [key, { message: value[0] }];
    })));
}
export function backendFormResolver(validateFn, fields = null) {
    return async (data) => {
        // There are quite some ts-ignores inside here. I'm sorry, not sure how this can be fixed. The outside APIs are safe though.
        const result = await validateFn(data);
        if (result === null)
            return { values: data, errors: {} };
        const errors = mapBackendErrorsToResolver(result, fields);
        if (Object.keys(errors).length > 0) {
            return { values: {}, errors };
        }
        return { values: data, errors: {} };
    };
}
