export function sortProductsByCategory(lineItemGroupA, lineItemGroupB) {
    const priority = {
        MATERIAL: 1,
        MATERIAL_TRANSPORT: 2,
        TRANSPORT: 3,
        MATERIAL_SERVICE: 4,
        TRANSPORT_SERVICE: 5,
    };

    if (priority[lineItemGroupA.productType] > priority[lineItemGroupB.productType]) {
        return 1;
    }

    if (priority[lineItemGroupB.productType] > priority[lineItemGroupA.productType]) {
        return -1;
    }

    return 0;
}
