import { useConstructionProjectById, useConstructionProjectBySalesForceId } from '../../hooks/api';
export function useCheckExistingConstructionProject(id, salesForceId) {
    const projectById = useConstructionProjectById(id);
    const projectBySalesForceId = useConstructionProjectBySalesForceId(salesForceId);
    /**
     * If salesForce id and project id point to different projects, this should cause a warning/error
     */
    const idMismatch = projectById.data && projectBySalesForceId.data && projectById.data.id !== projectBySalesForceId.data.id;
    const isNew = projectById.data === null && projectBySalesForceId.data === null;
    const project = projectById.data ?? projectBySalesForceId.data ?? null;
    const isLoading = projectById.isLoading || projectBySalesForceId.isLoading;
    return { isNew, idMismatch, project, isLoading };
}
