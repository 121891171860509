import { getIntervals } from '@/constants/billingInterval';
import { useGlobalVariable } from '../../../hooks/globalVariable';
export function useBillingIntervals() {
    const { data, isLoading } = useGlobalVariable('finance_allow_monthly_invoice_billing_interval', false);
    return {
        isLoading,
        intervals: {
            invoice: getIntervals({ type: 'invoice', isManualEnabled: data ?? false }),
            creditNote: getIntervals({ type: 'credit_note', isManualEnabled: data ?? false }),
        },
    };
}
