import { FieldError } from '@/pages/Magic/components/FieldError';
import { PhoneSelector } from '@/pages/Magic/components/PhoneSelector';
import { useAvailableLocales } from '@/pages/Magic/hooks/locales';
import { Avatar, Button, Combobox, TextField, ToggleSwitch } from '@schuettflix/react-components';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useUserSubForm } from '../form';
import { BaseInformationFields } from './common';
import { useTranslation } from 'react-i18next';
export function BaseInformationEdit(props) {
    const { t } = useTranslation();
    const form = useUserSubForm(BaseInformationFields, {
        defaultValues: props.defaultValue,
        mode: 'all',
    });
    const availableLocales = useAvailableLocales();
    useEffect(() => {
        form.trigger();
    }, [form]);
    const errors = form.formState.errors;
    const mobile = form.watch('mobile');
    return (<form onSubmit={form.handleSubmit(props.onSave)}>
            <div className="mx-auto flex w-full max-w-screen-md flex-col">
                <p className="font-copy-lg">{t('pages.user.userForm.titlePreview.user')}</p>
                <p className="font-copy-md-strong pb-2 pt-8">{t('pages.user.userForm.previewInfo.title')}</p>
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2 flex items-center gap-4">
                        <Avatar size="md"/>
                        <div className="flex-1">
                            <TextField {...form.register('firstName')} label={t('pages.organization.view.label.name')}/>
                            <FieldError error={errors.firstName}/>
                        </div>
                        <div className="flex-1">
                            <TextField {...form.register('lastName')} label={t('pages.user.userForm.placeholder.lastName')}/>
                            <FieldError error={errors.lastName}/>
                        </div>
                    </div>
                    <div>
                        <TextField {...form.register('username')} label={t('pages.user.userForm.placeholder.username')} className="flex-1"/>
                        <FieldError error={errors.username}/>
                    </div>
                    <div>
                        <PhoneSelector value={mobile} onChange={value => form.setValue('mobile', value)}/>
                        <FieldError error={errors.mobile?.countryCode ?? errors.mobile?.number}/>
                    </div>
                    <div>
                        <TextField {...form.register('email')} label={t('pages.user.userForm.placeholder.emailWithAsterisk')}/>
                        <FieldError error={errors.email}/>
                    </div>

                    <div>
                        <Controller control={form.control} name="locale" render={({ field }) => (<Combobox {...field} options={availableLocales.map(l => ({ value: l.locale, label: l.displayName }))} label={t('pages.user.userForm.placeholder.locale')}/>)}/>
                        <FieldError error={errors.locale}/>
                    </div>
                </div>
                <div>
                    <p className="font-copy-md-strong pb-2 pt-6">{t('pages.user.userForm.statusLabel')}</p>
                    <div className="pb-6">
                        <Controller control={form.control} name="isActive" render={({ field }) => (<ToggleSwitch {...field} checked={field.value} label={t(field.value
                ? 'pages.user.userForm.statusActiveLabel'
                : 'pages.user.userForm.statusInactiveLabel')} size="sm"/>)}/>
                    </div>
                    <p className="font-copy-md">
                        <span className="font-copy-md-strong inline text-critical">{t('components.hint.label')}</span>{' '}
                        {t('pages.user.userForm.newUser.hint.message')}
                    </p>
                    <p className="font-copy-sm pt-2">{t('pages.user.userForm.newUser.emailAddressHint')}</p>
                </div>

                <div className="flex justify-center gap-4 py-16">
                    <Button type="reset" onClick={props.onCancel} label={t('pages.organization.form.button.cancel.label')} size="sm" variant="secondary"/>
                    <Button type="submit" label={t('pages.organization.form.button.submit.label')} size="sm"/>
                </div>
                <hr className="border-divider pb-16"/>
            </div>
        </form>);
}
