import { createContext, useContext } from 'react';
const VueToasterContext = createContext(null);
export function useToaster() {
    const ctx = useContext(VueToasterContext);
    if (ctx === null) {
        throw new Error('useToaster must be used within a VueToasterContext');
    }
    return ctx;
}
export function VueToastProvider(props) {
    return <VueToasterContext.Provider value={props.toaster}>{props.children}</VueToasterContext.Provider>;
}
