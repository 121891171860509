import { useTranslation } from 'react-i18next';
import { PRODUCT_CATEGORY_LABELS } from '../utils/ProductCategoryLabels';
export const useCategoryLabelByGroupType = () => {
    const { t } = useTranslation();
    const resolveCategoryLabelByGroupType = (category, groupType) => {
        if (groupType === 'MERCHANT' && category === 'MATERIAL') {
            return t(PRODUCT_CATEGORY_LABELS.MATERIAL_TRANSPORT);
        }
        return t(PRODUCT_CATEGORY_LABELS[category]);
    };
    return {
        resolveCategoryLabelByGroupType,
    };
};
