import { SelectOrganization } from '@/pages/Magic/components/SelectOrganization';
import { MagicContext } from '@/pages/Magic/utils/context';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@schuettflix/planum-react';
import { Combobox } from '@schuettflix/react-components';
import { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
function userDescription(user) {
    const str = [];
    for (const field of ['username', 'email']) {
        if (typeof user.data[field] === 'string') {
            str.push(user.data[field]);
        }
    }
    return str.join(' - ');
}
function userLabel(user) {
    const str = [];
    for (const field of ['firstName', 'lastName']) {
        if (typeof user.data[field] === 'string') {
            str.push(user.data[field]);
        }
    }
    return str.join(' ');
}
const UserMetadataSchema = z.object({
    organizationId: z.number(),
    prefillData: z.string().optional(),
});
export function UserMetadata(props) {
    const { t } = useTranslation();
    const ctx = useContext(MagicContext);
    const form = useForm({
        resolver: zodResolver(UserMetadataSchema),
        mode: 'all',
    });
    const orgId = form.watch('organizationId');
    const org = ctx.state.savedOrganizations.find(org => org.id === orgId);
    const usersAvailableToPrefill = ctx.state.magicData?.users
        .filter(user => {
        // Check if the user belongs to the selected organization, either by platform or salesforce id
        const orgRefs = user.extra?.refs?.organization;
        return (orgRefs &&
            (orgRefs.platformId?.toString() === org?.id.toString() ||
                orgRefs.salesforceAccountId === org?.salesforceAccountId));
    })
        .map(user => ({
        label: userLabel(user),
        value: JSON.stringify(user),
        description: userDescription(user),
    }))
        .filter(option => !ctx.state.prefilledUsers.includes(option.value)) ?? [];
    return (<form onSubmit={form.handleSubmit(data => props.onSelect({
            organizationId: data.organizationId,
            prefillData: data.prefillData ? JSON.parse(data.prefillData) : undefined,
        }))} className="flex flex-col gap-4">
            <Controller control={form.control} name="organizationId" render={({ field }) => (<SelectOrganization {...field} label={t('pages.magic.organization.form.selectOrg')} 
        // If we already saved organizations, only use those
        availableOrganizations={ctx.state.savedOrganizations}/>)}/>

            {!!orgId && (<Controller control={form.control} name="prefillData" render={({ field }) => (<Combobox {...field} multiple={false} disabled={usersAvailableToPrefill.length === 0} options={usersAvailableToPrefill} label="Optionally, prefill user"/>)}/>)}

            <div className="flex justify-end">
                <Button type="submit" isDisabled={!form.formState.isValid}>
                    Continue
                </Button>
            </div>
        </form>);
}
