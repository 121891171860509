import { parsePhoneNumber } from 'awesome-phonenumber';
import { z } from 'zod';
const phoneSchema = z.string().transform(value => {
    const parsed = parsePhoneNumber(value, { regionCode: 'DE' });
    if (parsed.valid) {
        return { countryCode: `+${parsed.countryCode}`, number: parsed.number.national };
    }
    return null;
});
// Refs
const organizationRefSchema = z.object({
    platformId: z.string().optional(),
    salesforceAccountId: z.string().optional(),
});
const userRefSchema = z.object({
    platformId: z.string().optional(),
    salesforceContactId: z.string().optional(),
});
// Complete Link
const magicLinkDataSchema = z.object({
    organizations: z
        .array(z
        .object({
        data: z.object({}).passthrough(),
        extra: z
            .object({
            phone: phoneSchema.optional(),
            mobile: phoneSchema.optional(),
            fax: phoneSchema.optional(),
            address: z
                .string()
                .transform(s => {
                const hasNumber = /\d+/.exec(s);
                if (hasNumber?.[0]) {
                    const number = hasNumber[0];
                    return { street: s.replaceAll(number, '').trim(), number };
                }
            })
                .optional()
                .describe('Street with number'),
        })
            .optional(),
    })
        .transform(org => {
        if (org.extra) {
            if (org.extra.mobile) {
                org.data.mobile = org.extra.mobile;
            }
            if (org.extra.phone) {
                org.data.phone = org.extra.phone;
            }
            if (org.extra.fax) {
                org.data.fax = org.extra.fax;
            }
            if (org.extra.address) {
                if (!org.data.billingAddress) {
                    org.data.billingAddress = {};
                }
                if (typeof org.data.billingAddress === 'object' && org.data.billingAddress !== null) {
                    Object.assign(org.data.billingAddress, org.extra.address);
                }
            }
        }
        return org;
    }))
        .min(0)
        .max(1),
    users: z.array(z
        .object({
        data: z.object({}).passthrough(),
        extra: z
            .object({
            refs: z
                .object({
                organization: organizationRefSchema.optional(),
            })
                .optional(),
            mobile: phoneSchema.optional(),
        })
            .optional(),
    })
        .transform(user => {
        if (user.extra) {
            if (user.extra.mobile) {
                user.data.mobile = user.extra.mobile;
            }
        }
        return user;
    })),
    constructionProjects: z
        .array(z.object({
        data: z.object({}).passthrough(),
        extra: z
            .object({
            refs: z
                .object({
                responsibleEmployee: userRefSchema.optional(),
                responsibleUser: userRefSchema.optional(),
                organization: organizationRefSchema.optional(),
            })
                .optional(),
        })
            .optional(),
    }))
        .min(0)
        .max(1),
});
export function extractDataFromUrl() {
    const searchParams = new URLSearchParams(window.location.hash.slice(window.location.hash.indexOf('?') + 1));
    const dataAsString = searchParams.get('data');
    if (!dataAsString)
        return null;
    try {
        const data = JSON.parse(dataAsString);
        return magicLinkDataSchema.parse(data);
    }
    catch (e) {
        // TODO: Pass the error to the user
        console.error(e);
        return null;
    }
}
