import AbstractResource from '../AbstractResource';
class Utils extends AbstractResource {
    /**
     * Get one resource object
     */
    async getPlatformInfo() {
        try {
            const response = await this.apiService.get('platforminfo');
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    /**
     * Get one resource object
     */
    async getFeatureFlags() {
        try {
            const response = await this.apiService.get('featureflag');
            return response.data;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
    /**
     * Get platform version
     * @param {int} id
     */
    async getPlatformVersion() {
        try {
            const response = await this.apiService.get('/');
            return response.data.version;
        }
        catch (err) {
            throw this.handleError(err);
        }
    }
}
export default new Utils();
