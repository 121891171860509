import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@schuettflix/react-components';
import { createAdminConstructionProject } from '../../api/projects';
import { useVueRouter } from '@/reactBridge/VueRouterProvider';
import { FormField } from '@/constructionProjects/components/FormField';
import Toaster from '@/services/Toaster';
import { AnalyticsService } from '@/services/Analytics/AnalyticsService';
import { adminConstructionProjectOrganizationOptionsQuery, adminConstructionProjectOrganizationsQuery, constructionProjectKeys, } from '@/constructionProjects/queries';
import { formatAddress } from '@/constructionProjects/utils/formatAddress';
import { ConstructionProjectForm, useConstructionProjectForm, } from '@/constructionProjects/pages/ConstructionProjectCreate/ConstructionProjectForm';
import { format, addDays } from 'date-fns';
import { useIsApp } from '@/constructionProjects/hooks/useIsApp';
import { useBreakpoints } from '@/constructionProjects/hooks/useBreakpoints';
import { wait } from '@/services/utils/wait';
import { EventBus } from '@/services/EventBus';
import { EVENT_PROJECT_CREATED } from '@/constants/events';
export const ConstructionProjectAdminCreateForm = ({ onSubmit, onCancel, }) => {
    const { t, i18n } = useTranslation();
    const { vueRouter } = useVueRouter();
    const queryClient = useQueryClient();
    const suggestedOrganization = typeof vueRouter.currentRoute.query.organization === 'string'
        ? parseInt(vueRouter.currentRoute.query.organization)
        : NaN;
    const localizeComparator = new Intl.Collator(i18n.language);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(isNaN(suggestedOrganization) ? null : suggestedOrganization);
    const [selectedPlatformAdmin, setSelectedPlatformAdmin] = useState(null);
    const { data: organizations } = useQuery({
        ...adminConstructionProjectOrganizationsQuery,
        suspense: true,
    });
    const { data: adminOrganizationOptions } = useQuery({
        ...adminConstructionProjectOrganizationOptionsQuery(selectedOrganizationId ?? -1),
        enabled: !!selectedOrganizationId,
    });
    const mutation = useMutation(createAdminConstructionProject, {
        onSuccess: () => {
            AnalyticsService.trackEvent('construction_project', { step: 'construction_project_created' });
            queryClient.invalidateQueries(constructionProjectKeys.list());
            Toaster.success(t('pages.constructionProject.createForm.toaster.success'));
            // Legacy projects are synced via CDC which is an async process. We need to wait a bit
            // to make sure the project is created in the legacy system.
            wait(2000).then(() => {
                EventBus.$emit(EVENT_PROJECT_CREATED);
            });
        },
        onError: (error) => {
            if (!error.response || error.response.status > 400) {
                Toaster.error(t('pages.constructionProject.createForm.toaster.unspecifiedServerError'));
            }
        },
    });
    const date = new Date();
    const isApp = useIsApp();
    const { isSmallerOrEqual } = useBreakpoints();
    const { form } = useConstructionProjectForm({
        defaultValues: {
            color: null,
            validFrom: format(date, 'YYYY-MM-DD'),
            validTo: isApp || isSmallerOrEqual('md') ? format(addDays(date, 30), 'YYYY-MM-DD') : undefined,
            teamMembers: [],
            active: true,
            responsibleUserId: selectedPlatformAdmin ?? -1,
            orderConfirmationMails: [],
        },
        costCenterMandatory: adminOrganizationOptions?.organizationMetadata?.costCenterMandatory ?? false,
    });
    const onSubmitConstructionProjectCreateForm = (formData) => {
        if (!selectedOrganizationId || !selectedPlatformAdmin) {
            return;
        }
        const data = {
            name: formData.name,
            description: formData.description,
            validFrom: formData?.validFrom ? new Date(formData.validFrom).toISOString() : '',
            validTo: formData?.validTo ? new Date(formData.validTo).toISOString() : '',
            costCenter: formData.costCenter,
            color: formData.color,
            active: formData.active,
            bounds: [],
            responsibleUserId: formData.responsibleUserId,
            teamMembers: Array.from(new Set(formData.teamMembers)),
            city: formData.location.address.city,
            zip: formData.location.address.zipCode,
            street: formData.location.address.street,
            addressNumber: formData.location.address.streetNumber,
            geoCoordinate: formData.location.coordinates,
            organizationId: selectedOrganizationId,
            responsibleEmployeeId: selectedPlatformAdmin,
            orderConfirmationMails: formData.orderConfirmationMails,
            salesforceProjectId: null,
        };
        mutation.mutate(data, {
            onSuccess: () => {
                onSubmit();
            },
            onError: (error) => {
                if (error.response?.status === 400) {
                    const fieldErrors = error.response?.data?.fieldErrors;
                    for (const [key, error] of Object.entries(fieldErrors)) {
                        form.setError(
                        // @ts-expect-error These are backend keys and are untypable
                        key, {
                            type: 'server',
                            message: error.message,
                        }, { shouldFocus: true });
                    }
                }
            },
        });
    };
    const orgOptions = organizations?.organizations?.map(organization => ({
        value: organization.id,
        label: organization.name,
        description: formatAddress(organization.billingAddress),
    }));
    const responsibleEmployeeOptions = adminOrganizationOptions?.platformUsers?.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }));
    return (<>
            <div className="container-content flex flex-col bg py-6">
                <FormField title={t('pages.constructionProject.createForm.organizationSelection.title')} description={t('pages.constructionProject.createForm.organizationSelection.description')}>
                    <Combobox className="mb-12 w-full" label={t('pages.constructionProject.createForm.organizationSelection.title')} multiple={false} options={orgOptions ?? []} value={selectedOrganizationId} onChange={value => setSelectedOrganizationId(value)}/>
                </FormField>
                {selectedOrganizationId ? (<FormField title={t('pages.constructionProject.createForm.responsibleEmployeeId.title')} description={t('pages.constructionProject.createForm.responsibleEmployeeId.description')}>
                        <Combobox className="w-full" label={t('pages.constructionProject.createForm.personField.label')} multiple={false} options={responsibleEmployeeOptions?.sort((a, b) => localizeComparator.compare(a.label, b.label)) ?? []} value={selectedPlatformAdmin} onChange={value => setSelectedPlatformAdmin(value)}/>
                    </FormField>) : null}
            </div>
            {selectedOrganizationId && selectedPlatformAdmin && adminOrganizationOptions ? (<ConstructionProjectForm form={form} organizationUsers={adminOrganizationOptions.organizationUsers} organizationEmail={adminOrganizationOptions.organizationMetadata.email} selectedOrganizationId={selectedOrganizationId} submissionLoading={mutation.isLoading} onCancelClick={() => onCancel()} onSubmit={onSubmitConstructionProjectCreateForm} submitButtonLabel={t('pages.constructionProject.createForm.submitButton.label')} cancelButtonLabel={t('pages.constructionProject.createForm.cancelButton.label')} costCenterMandatory={adminOrganizationOptions.organizationMetadata.costCenterMandatory}/>) : null}
        </>);
};
